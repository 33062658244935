import { useSelector } from "react-redux";
import { getAuthenticatedDataRoomUser } from "../store/auth/AuthSelectors";
import { RouteProps, Route, Redirect } from "react-router-dom";
import { addSearchParameters } from "../utils/url";

export const DataRoomRoute = ({ children, ...rest }: RouteProps) => {
    const dataRoomUser = useSelector(getAuthenticatedDataRoomUser);

    return <Route
        {...rest}
        render={props => dataRoomUser
            ? children
            : <Redirect
                to={{
                    pathname: '/dataroom/login',
                    search: addSearchParameters(location.search, { path: props.location.pathname }),
                }}
            />
        }
    />;
};
