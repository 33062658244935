import { config } from '../config';

export const dropboxSrc = 'https://www.dropbox.com/static/api/2/dropins.js';

export const dropboxScriptConfig = {
    'id': 'dropboxjs',
    'dataAttr': [
        { 'appKey': config.dropboxAppKey },
    ],
};
