import { useSelector } from "react-redux";
import { getAuthenticatedBorrower } from "../store/auth/AuthSelectors";
import { RouteProps, Route, Redirect } from "react-router-dom";
import { addSearchParameters } from "../utils/url";

export const BorrowerRoute = ({ children, ...rest }: RouteProps) => {
    const borrower = useSelector(getAuthenticatedBorrower);

    return <Route
        {...rest}
        render={props => borrower
            ? children
            : <Redirect
                to={{
                    pathname: '/login',
                    search: addSearchParameters(location.search, { path: props.location.pathname }),
                }}
            />
        }
    />;
};
