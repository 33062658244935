import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { AuthActions } from "../../store/auth/AuthActions";

export const Logout = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(AuthActions.dataRoomLogout(history));
    }, [dispatch, history]);

    return null;
};
