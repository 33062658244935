import axios, { AxiosError } from 'axios';
import { config } from '../config';
import { Role } from '../constants/Role';
import { StorageKey } from '../constants/StorageKey';
import { ApiErrorModel } from '../models/ApiErrorModel';
import { parseJWT } from './jwt';
import { storage } from './storage';

let tokenFetching = false;

const authClient = axios.create({
    baseURL: config.authUrl,
});
const apiClient = axios.create({
    baseURL: config.apiUrl,
});

const getToken = (roleId: number) => {
    switch (roleId) {
        case Role.Borrower:
            return storage.get(StorageKey.BORROWER_TOKEN);
        case Role.DataRoom:
            return storage.get(StorageKey.DATA_ROOM_TOKEN);
        default:
            return null;
    }
};

const getRefreshToken = (roleId: number) => {
    switch (roleId) {
        case Role.Borrower:
            return storage.get(StorageKey.BORROWER_REFRESH_TOKEN);
        case Role.DataRoom:
            return storage.get(StorageKey.DATA_ROOM_REFRESH_TOKEN);
        default:
            return null;
    }
};

const setToken = (roleId: number, token: string) => {
    if (roleId === Role.Borrower) {
        storage.put(StorageKey.BORROWER_TOKEN, token);
    } else if (roleId === Role.DataRoom) {
        storage.put(StorageKey.DATA_ROOM_TOKEN, token);
    }
};

const setRefreshToken = (roleId: number, refreshToken: string) => {
    if (roleId === Role.Borrower) {
        storage.put(StorageKey.BORROWER_REFRESH_TOKEN, refreshToken);
    } else if (roleId === Role.DataRoom) {
        storage.put(StorageKey.DATA_ROOM_REFRESH_TOKEN, refreshToken);
    }
};

const interceptApiClientError = async (error: AxiosError<ApiErrorModel> & { config: { headers: { authorization: string } } }) => {
    if (error.response?.status === 401) {
        let newToken: string|null = null;

        const headers: { authorization: string } = error.config?.headers;
        const authorizationHeader = headers.authorization;
        const token = authorizationHeader.split(' ')[1];
        const parsedToken = parseJWT<{ role_id: number }>(token);
        const role = parsedToken.role_id;

        if (tokenFetching) {
            // trick to send only one refresh-token request in case of concurrent requests
            await new Promise<void>((resolve) => {
                const intervalId = setInterval(() => {
                    if (!tokenFetching) {
                        clearInterval(intervalId);
                        resolve();
                    }
                }, 10);
            });
            newToken = getToken(role);
        } else {
            tokenFetching = true;

            try {
                const refreshToken = getRefreshToken(role);
                const response = await authClient.post<{
                    data: { token: string; refreshToken: string };
                }>(
                    '/api/refresh-token',
                    { token, refreshToken },
                );

                newToken = response.data.data.token;
                const newRefreshToken = response.data.data.refreshToken;

                setToken(role, newToken);
                setRefreshToken(role, newRefreshToken);
            } finally {
                tokenFetching = false;
            }
        }

        if (newToken) {
            headers.authorization = `bearer ${newToken}`;
            return axios.request(error.config);
        }
    }

    return Promise.reject(error);
};

apiClient.interceptors.response.use(response => response, interceptApiClientError);

export { apiClient, authClient };
