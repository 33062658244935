import { applyMiddleware, createStore, Middleware, PreloadedState, Store, StoreEnhancer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';
import { AppState } from './state';

export const createAppStore = (preloadedState?: PreloadedState<AppState>): Store<AppState> => {
    const sagaMiddleware = createSagaMiddleware();

    const middlewares: Middleware[] = [sagaMiddleware];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers: StoreEnhancer[] = [middlewareEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);

    const store = createStore(reducers(), preloadedState, composedEnhancers);

    sagaMiddleware.run(sagas);

    return store;
};
