import { AxiosInstance } from 'axios';
import { apiClient, authClient } from '../utils/api';
import { BorrowerModel } from '../models/auth/BorrowerModel';
import { RegisterUserModel } from '../models/auth/RegisterUserModel';
import { storage } from '../utils/storage';
import { StorageKey } from '../constants/StorageKey';
import moment from 'moment';
import { DataRoomUserModel } from '../models/auth/DataRoomUserModel';

class AuthService {
    constructor(
        private apiClient: AxiosInstance,
        private authClient: AxiosInstance,
    ) {}

    public register = async (userData: RegisterUserModel): Promise<string> => {
        const response = await this.apiClient.post<{ data: { token: string } }>('/borrowers', { ...userData, password_confirmation: userData.password });
        return response.data.data.token;
    };

    public authenticatedBorrower = async (): Promise<BorrowerModel> => {
        const token = storage.get(StorageKey.BORROWER_TOKEN);
        const response = await this.apiClient.get<{ data: BorrowerModel }>('/borrowers/me', {
            headers: {
                'authorization': token && `bearer ${token}`,
            },
        });

        return {
            ...response.data.data,
            dateOfBirth: moment(response.data.data.dateOfBirth),
        };
    };

    public checkBorrowerEmailAvailable = async (email: string) => {
        const response = await this.apiClient.post<{ data: { available: boolean } }>('/borrowers/check-email', { email });

        return response.data.data.available;
    };

    public authenticatedDataRoomUser = async (): Promise<DataRoomUserModel> => {
        const token = storage.get(StorageKey.DATA_ROOM_TOKEN);

        const response = await this.apiClient.get<{ data: DataRoomUserModel }>('/dataroom/users/me', {
            headers: {
                'authorization': token && `bearer ${token}`,
            },
        });

        return response.data.data;
    };
}

export const authService = new AuthService(apiClient, authClient);
