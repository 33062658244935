import './App.css';
import { Provider } from 'react-redux';
import { createAppStore } from './store';
import { Router } from './Router';
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { initializeI18n } from './localizations';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { AuthenticationProvider } from "./components/AuthenticationProvider";
import * as Yup from 'yup';
import { sequenceSchema } from './utils/validation';

const store = createAppStore();

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#ec6333',
        },
    },
    typography: {
        fontFamily: 'Futura Md',
    },
});

initializeI18n();
Yup.addMethod(Yup.StringSchema, 'sequence', sequenceSchema);

function App() {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <AuthenticationProvider>
                        <CssBaseline />
                        <Router />
                    </AuthenticationProvider>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </Provider>
    );
}

export default App;
