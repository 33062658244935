export enum LoanStatus {
    PIPELINE_NAI = 20,
    FUNDED_NAI = 25,
    ACTIVE_PARTIALLY_FUNDED_AI_PI = 40,
    ACTIVE_FUNDED_AI_PI = 50,
    ACTIVE_FUNDED_IN_ARREARS_PI = 53,
    ACTIVE_FUNDED_AI_NPI = 55,
    ACTIVE_FUNDED_NAI = 60,
    DEFAULTED_NAI = 70,
    REPAID_NAI = 80,
}
