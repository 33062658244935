import { Box, Tab, Typography } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Loader } from "../../../components/Loader";
import { LoanActions } from "../../../store/loan/LoanActions";
import { getBorrowerLoanById, getBorrowerLoanLoading } from "../../../store/loan/LoanSelectors";
import { DocumentsTab } from "./DocumentsTab";
import { SummaryTab } from "./SummaryTab";

enum LoanTabs {
    Summary = 'summary',
    Documents = 'documents',
}

export const LoanContainer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const loan = useSelector(getBorrowerLoanById(Number(id), null));

    const [currentTab, setCurrentTab] = useState(LoanTabs.Summary);

    const borrowerLoanLoading = useSelector(getBorrowerLoanLoading);

    useEffect(() => {
        if (!loan) {
            dispatch(LoanActions.getBorrowerLoan(Number(id)));
        }
    }, [dispatch, loan, id]);

    const handleTabChange = (event: React.ChangeEvent<unknown>, value: LoanTabs) => {
        setCurrentTab(value);
    };

    return <Box>
        { borrowerLoanLoading || !loan
            ? <Loader wide centered />
            : (
                <Fragment>
                    <Typography variant="h4" align="center" gutterBottom>{loan.displayName}</Typography>
                    <TabContext value={currentTab}>
                        <TabList
                            onChange={handleTabChange}
                            textColor="primary"
                            indicatorColor="primary"
                            centered
                        >
                            <Tab label={t('Summary')} value={LoanTabs.Summary} />
                            <Tab label={t('Documents')} value={LoanTabs.Documents} />
                        </TabList>
                        <TabPanel value={LoanTabs.Summary}>
                            <SummaryTab loan={loan} />
                        </TabPanel>
                        <TabPanel value={LoanTabs.Documents}>
                            <DocumentsTab loan={loan} />
                        </TabPanel>
                    </TabContext>
                </Fragment>
            )
        }
    </Box>;
};
