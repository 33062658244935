import { combineReducers } from 'redux';
import { authReducer } from './auth/AuthReducer';
import { loanReducer } from './loan/LoanReducer';
import { loanApplicationReducer } from './loanApplication/LoanApplicationReducer';
import { systemReducer } from './system/SystemReducer';

export default () => combineReducers({
    auth: authReducer,
    loan: loanReducer,
    loanApplication: loanApplicationReducer,
    system: systemReducer,
});
