export const formatPounds = (amount: number) => amount.toLocaleString('en-GB', {
    style: 'currency',
    currency: 'GBP',
}).split('.')[0];

export const shortenFormatPounds = (amount: number): string => {
    const poundSign = '\u20A4';
    let number = '';

    if (amount >= 1e6) {
        number = (amount / 1e6).toFixed(1) + 'm';
    } else if (amount >= 1e3) {
        number = (amount / 1e3).toFixed(1) + 'k';
    } else {
        number = amount.toFixed(0);
    }

    return poundSign + number;
};
