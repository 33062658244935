import { AxiosInstance } from 'axios';
import { FindAddressModel } from '../models/system/FindAddressModel';
import { apiClient } from '../utils/api';

class SystemService {
    constructor(
        private apiClient: AxiosInstance,
    ) {}

    public getCountries = async (): Promise<Record<string, string>> => {
        const response = await this.apiClient.get<{ data: Record<string, string> }>('/system/countries');
        return response.data.data;
    };

    public findAddress = async (postCode: string, houseNumberName?: string): Promise<FindAddressModel[]> => {
        const response = await this.apiClient.get<{ data: FindAddressModel[] }>('/system/find-address', { params: { postCode, houseNumberName } });
        return response.data.data;
    };
}

export const systemService = new SystemService(apiClient);
