import { all, call, put, takeLatest } from 'redux-saga/effects';
import { BorrowerLoanStatus } from '../../constants/BorrowerLoanStatus';
import { loanService } from '../../services/loanService';
import { downloadFileFromResponse } from '../../utils/download';
import {
    GET_ACTIVE_BORROWER_LOANS,
    GET_BORROWER_LOAN,
    GET_COMPLETED_BORROWER_LOANS,
    GET_STATISTICS,
    GET_DATA_ROOM_LOAN,
    GET_DATA_ROOM_LOANS,
    GET_DATA_ROOM_LOANS_STATISTICS,
    GET_DROPBOX_LOAN_LINK,
    LoanActions,
    GET_PENDING_BORROWER_LOANS,
    DOWNLOAD_BORROWER_LOAN_STATEMENT,
    GET_DECLINED_BORROWER_LOANS,
} from './LoanActions';

function* getPendingBorrowerLoans(action: ReturnType<typeof LoanActions.getPendingBorrowerLoans>) {
    try {
        const { page, pageSize } = action.payload;
        const [borrowerLoans, total]: Awaited<ReturnType<typeof loanService.getBorrowerLoans>> =
            yield call(loanService.getBorrowerLoans, BorrowerLoanStatus.Pending, page, pageSize);

        yield put(LoanActions.getPendingBorrowerLoansSuccess(borrowerLoans, total, page, pageSize));
    } catch (e) {
        yield put(LoanActions.getPendingBorrowerLoansFailed());
    }
}

function* getDeclinedBorrowerLoans(action: ReturnType<typeof LoanActions.getDeclinedBorrowerLoans>) {
    try {
        const { page, pageSize } = action.payload;
        const [borrowerLoans, total]: Awaited<ReturnType<typeof loanService.getBorrowerLoans>> =
            yield call(loanService.getBorrowerLoans, BorrowerLoanStatus.Declined, page, pageSize);

        yield put(LoanActions.getDeclinedBorrowerLoansSuccess(borrowerLoans, total, page, pageSize));
    } catch (e) {
        yield put(LoanActions.getDeclinedBorrowerLoansFailed());
    }
}

function* getActiveBorrowerLoans(action: ReturnType<typeof LoanActions.getActiveBorrowerLoans>) {
    try {
        const { page, pageSize } = action.payload;
        const [borrowerLoans, total]: Awaited<ReturnType<typeof loanService.getBorrowerLoans>> =
            yield call(loanService.getBorrowerLoans, BorrowerLoanStatus.Active, page, pageSize);

        yield put(LoanActions.getActiveBorrowerLoansSuccess(borrowerLoans, total, page, pageSize));
    } catch (e) {
        yield put(LoanActions.getActiveBorrowerLoansFailed());
    }
}

function* getCompletedBorrowerLoans(action: ReturnType<typeof LoanActions.getCompletedBorrowerLoans>) {
    try {
        const { page, pageSize } = action.payload;
        const [borrowerLoans, total]: Awaited<ReturnType<typeof loanService.getBorrowerLoans>> =
            yield call(loanService.getBorrowerLoans, BorrowerLoanStatus.Completed, page, pageSize);

        yield put(LoanActions.getCompletedBorrowerLoansSuccess(borrowerLoans, total, page, pageSize));
    } catch (e) {
        yield put(LoanActions.getCompletedBorrowerLoansFailed());
    }
}

function* getBorrowerLoan(action: ReturnType<typeof LoanActions.getBorrowerLoan>) {
    try {
        const { id } = action.payload;
        const loan: Awaited<ReturnType<typeof loanService.getBorrowerLoan>> =
            yield call(loanService.getBorrowerLoan, id);

        yield put(LoanActions.getBorrowerLoanSuccess(loan));
    } catch (e) {
        yield put(LoanActions.getBorrowerLoanFailed());
    }
}

function* getStatistics() {
    try {
        const statistics: Awaited<ReturnType<typeof loanService.getStatistics>> = yield call(loanService.getStatistics);

        yield put(LoanActions.getStatisticsSuccess(statistics));
    } catch (e) {
        yield put(LoanActions.getStatisticsFailed());
    }
}

function* getDataRoomLoans(action: ReturnType<typeof LoanActions.getDataRoomLoans>) {
    try {
        const { page, pageSize, column, order } = action.payload;
        const [dataRoomLoans, total]: Awaited<ReturnType<typeof loanService.getDataRoomLoans>> = yield call(loanService.getDataRoomLoans, page, pageSize, column, order);
        yield put(LoanActions.getDataRoomLoansSuccess(dataRoomLoans, total, page));
    } catch (e) {
        yield put(LoanActions.getDataRoomLoansFailed());
    }
}

function* getDataRoomLoansStatistics(action: ReturnType<typeof LoanActions.getDataRoomLoansStatistics>) {
    try {
        const statistics: Awaited<ReturnType<typeof loanService.getDataRoomLoansStatistics>> = yield call(loanService.getDataRoomLoansStatistics, action.payload.status);

        yield put(LoanActions.getDataRoomLoansStatisticsSuccess(statistics));
    } catch (e) {
        yield put(LoanActions.getDataRoomLoansStatisticsFailed());
    }
}

function* getDataRoomLoan(action: ReturnType<typeof LoanActions.getDataRoomLoan>) {
    try {
        const { id } = action.payload;
        const dataRoomLoan: Awaited<ReturnType<typeof loanService.getDataRoomLoan>> = yield call(loanService.getDataRoomLoan, id);
        yield put(LoanActions.getDataRoomLoanSuccess(dataRoomLoan));
    } catch (e) {
        yield put(LoanActions.getDataRoomLoanFailed());
    }
}

function* getDropboxLoanLink(action: ReturnType<typeof LoanActions.getDropboxLoanLink>) {
    try {
        const { loanCode } = action.payload;
        const { link }: Awaited<ReturnType<typeof loanService.getDropboxLoanLink>> = yield call(loanService.getDropboxLoanLink, loanCode);

        yield put(LoanActions.getDropboxLoanLinkSuccess(loanCode, link));
    } catch (e) {
        yield put(LoanActions.getDropboxLoanLinkFailed());
    }
}

function* downloadBorrowerLoanStatement(action: ReturnType<typeof LoanActions.downloadBorrowerLoanStatement>) {
    try {
        const { loanId } = action.payload;
        const response: Awaited<ReturnType<typeof loanService.downloadBorrowerLoanStatement>> = yield call(loanService.downloadBorrowerLoanStatement, loanId);

        downloadFileFromResponse(response);
        yield put(LoanActions.downloadBorrowerLoanStatementSuccess());
    } catch (e) {
        yield put(LoanActions.downloadBorrowerLoanStatementFailed());
    }
}

export function* loanSaga() {
    yield all([
        takeLatest(GET_PENDING_BORROWER_LOANS, getPendingBorrowerLoans),
        takeLatest(GET_DECLINED_BORROWER_LOANS, getDeclinedBorrowerLoans),
        takeLatest(GET_ACTIVE_BORROWER_LOANS, getActiveBorrowerLoans),
        takeLatest(GET_COMPLETED_BORROWER_LOANS, getCompletedBorrowerLoans),
        takeLatest(GET_BORROWER_LOAN, getBorrowerLoan),
        takeLatest(GET_STATISTICS, getStatistics),
        takeLatest(GET_DATA_ROOM_LOANS, getDataRoomLoans),
        takeLatest(GET_DATA_ROOM_LOANS_STATISTICS, getDataRoomLoansStatistics),
        takeLatest(GET_DATA_ROOM_LOAN, getDataRoomLoan),
        takeLatest(GET_DROPBOX_LOAN_LINK, getDropboxLoanLink),
        takeLatest(DOWNLOAD_BORROWER_LOAN_STATEMENT, downloadBorrowerLoanStatement),
    ]);
}
