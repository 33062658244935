import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoanActions } from '../../../store/loan/LoanActions';
import {
    getDropboxLoanLinkByLoanCode, getDropboxLoanLinkLoading,
} from '../../../store/loan/LoanSelectors';
import { Loader } from '../../../components/Loader';

interface DropboxWidgetProps {
    loanCode: string;
}

export const DropboxWidget = (props: DropboxWidgetProps) => {
    const { loanCode } = props;
    const dispatch = useDispatch();

    const link = useSelector(getDropboxLoanLinkByLoanCode(loanCode));
    const dropboxLoanLinkLoading = useSelector(getDropboxLoanLinkLoading);

    useEffect(() => {
        if (!link) {
            dispatch(LoanActions.getDropboxLoanLink(loanCode));
        }
    }, [dispatch, loanCode, link]);

    useEffect(() => {
        if (link) {
            const root = document.getElementById('dropbox-root');
            const el = document.createElement('div');
            el.style.height = '380px';

            const options = {
                link: link,
                height: "600px",
                file: {
                    zoom: "best",
                },
                folder: {
                    view: "list",
                    headerSize: "normal",
                },
            };

            if (root) {
                root.appendChild(el);
                const embed = window.Dropbox.embed(options, el);
                return () => {
                    window.Dropbox.unmount(embed);
                    root.removeChild(el);
                };
            }

        }
    }, [link]);

    return <>
        {link===null && !dropboxLoanLinkLoading
            ? <div>No files currently for this loan</div>
            : dropboxLoanLinkLoading && <Loader wide={true} centered={true} />}
        <div id={'dropbox-root'} />
    </>;
};
