import { Box, Chip, FormControl, Grid, InputLabel, makeStyles, MenuItem, Paper, Select, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { CheckCircle, Error, EventNote } from "@material-ui/icons";
import moment from "moment";
import { ChangeEvent, ReactNode, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../../../components/PrimaryButton";
import { SchedulePaymentStatus } from "../../../constants/SchedulePaymentStatus";
import { SchedulePaymentType } from "../../../constants/SchedulePaymentType";
import { BorrowerLoanModel } from "../../../models/loan/BorrowerLoanModel";
import { BorrowerLoanScheduleModel } from "../../../models/loan/BorrowerLoanScheduleModel";
import { LoanActions } from "../../../store/loan/LoanActions";
import { getBorrowerLoanStatementDownloading } from "../../../store/loan/LoanSelectors";
import { formatPounds } from "../../../utils/money";
import { ReactComponent as PendingOutlined } from "../../../assets/icons/pending-outlined.svg";
import { OverduePayments } from "./Components/OverduePayments";
import { NextDuePayments } from "./Components/NextDuePayments";
import { Payment } from "./Components/PaymentsTable";

interface ScheduleTabProps {
    loan: BorrowerLoanModel;
}

const useStyles = makeStyles((theme) => ({
    summaryPaper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    statementButton: {
        marginTop: theme.spacing(2),
    },
    drawdownContainer: {
        width: '100%',
    },
    scheduleTop: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
    },
    scheduleHead: {
        background: theme.palette.grey[100],
    },
    scheduleFooter: {
        marginTop: theme.spacing(1),
    },
    paymentIcon: {
        marginRight: theme.spacing(1),
    },
    nextPaymentsBlock: {
        marginTop: theme.spacing(2),
    },
}));

const findOverduePayments = (schedule: BorrowerLoanScheduleModel[]) => schedule
    .filter(item => item.paymentStatus === SchedulePaymentStatus.Pending && moment().diff(item.dueDate, 'days') > 0)
    .sort((left, right) => left.dueDate.diff(right.dueDate));

const findNextDuePayments = (schedule: BorrowerLoanScheduleModel[]) => schedule
    .filter(item =>
        item.paymentStatus === SchedulePaymentStatus.Pending
        && item.dueDate.isSameOrAfter(moment().startOf('day'))
        && item.dueDate.isSameOrBefore(moment().add(30, 'days')))
    .sort((left, right) => left.dueDate.diff(right.dueDate));

const calculateCapitalDue = (schedule: BorrowerLoanScheduleModel[]): number => schedule
    .filter(record => 
        [SchedulePaymentType.PartialCapitalRepayment, SchedulePaymentType.FinalCapitalRepayment]
            .includes(record.paymentTypeId)
        && record.paymentStatus === SchedulePaymentStatus.Pending
        && moment().diff(record.dueDate, 'days') > 0)
    .reduce((acc, record) => acc + record.totalAmount, 0);

const calculateInterestDue = (schedule: BorrowerLoanScheduleModel[]): number => schedule
    .filter(record => 
        record.paymentTypeId === SchedulePaymentType.Interest
        && record.paymentStatus === SchedulePaymentStatus.Pending
        && moment().diff(record.dueDate, 'days') > 0)
    .reduce((acc, record) => acc + record.totalAmount, 0);

const mapScheduleRecordToPayment = (loanCodeMap: Record<number, string>) => (schedule: BorrowerLoanScheduleModel): Payment => ({
    dueDate: schedule.dueDate,
    loanCode: loanCodeMap[schedule.loanId],
    description: schedule.description,
    amount: schedule.totalAmount,
});

export const SummaryTab = (props: ScheduleTabProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { loan } = props;

    const drawdowns = useMemo(() => [
        {
            label: loan.displayName || '',
            schedule: loan.schedule,
        },
        ...loan.tranches.map(tranche => ({
            label: tranche.displayName,
            schedule: tranche.schedule,
        })),
    ], [loan]);

    const loanCodeMap: Record<number, string> = {
        [loan.loanId || 0]: loan.loanCode,
        ...loan.tranches.reduce((acc, tranche) => ({ ...acc, [tranche.loanId]: tranche.loanCode }), {}),
    };

    const allScheduleRecords = useMemo(() => [
        ...loan.schedule,
        ...loan.tranches.reduce((acc, tranche) => [...acc, ...tranche.schedule], [] as BorrowerLoanScheduleModel[]),
    ], [loan]);

    const [selectedDrawdownIndex, setSelectedDrawdownIndex] = useState<number|''>('');
    const selectedDrawdown = drawdowns[selectedDrawdownIndex || 0];

    const borrowerStatementDownloading = useSelector(getBorrowerLoanStatementDownloading);

    const overdueSchedule = findOverduePayments(allScheduleRecords);
    const overdueAmount = overdueSchedule.reduce((acc, item) =>  acc + item.totalAmount, 0);
    const isOverdue = overdueSchedule.length > 0;
    const overduePayments = overdueSchedule.map(mapScheduleRecordToPayment(loanCodeMap));

    const nextDueSchedule = findNextDuePayments(allScheduleRecords);
    const nextDueAmountAmount = nextDueSchedule.reduce((acc, item) => acc + item.totalAmount, 0);
    const nextDuePayments = nextDueSchedule.map(mapScheduleRecordToPayment(loanCodeMap));

    const renderPaymentStatus = (payment: BorrowerLoanScheduleModel) => {
        if (payment.paymentStatus === SchedulePaymentStatus.Pending && moment().diff(payment.dueDate, 'days') > 0) {
            return <Box display="flex" alignItems="center">
                <Error fontSize="small" className={classes.paymentIcon} />{t('Overdue')}
            </Box>;
        }

        if (payment.paymentStatus === SchedulePaymentStatus.Pending) {
            return <Box display="flex" alignItems="center">
                <SvgIcon component={PendingOutlined} fontSize="small" className={classes.paymentIcon} />{t('Pending')}
            </Box>;
        }

        if (payment.paymentStatus === SchedulePaymentStatus.Paid) {
            return <Box display="flex" alignItems="center">
                <CheckCircle fontSize="small" className={classes.paymentIcon} />{t('Paid')}
            </Box>;
        }

        return null;
    };

    const header = ['Date', 'Description', 'Amount', 'Status'];
    const body = selectedDrawdown.schedule.sort((a, b) => a.dueDate.diff(b.dueDate)).map(item => [
        item.dueDate.format('DD/MM/YYYY'),
        item.description,
        formatPounds(item.totalAmount),
        renderPaymentStatus(item),
    ]);

    const handleStatementDownload = useCallback((loanId: number) => {
        dispatch(LoanActions.downloadBorrowerLoanStatement(loanId));
    }, [dispatch]);

    const handleDrawdownChange = (event: ChangeEvent<{ value: unknown }>) => {
        setSelectedDrawdownIndex(event.target.value as number);
    };

    const details: ReactNode[][] = [
        [t('Total Outstanding'), formatPounds(loan.totalOutstandingLoanAmount as number)],
        [t('Drawdowns to Date'), loan.tranches.length + 1],
        [t('Available Facility'), loan.undrawnFacility === null ? '-' : formatPounds(loan.undrawnFacility)],
        [t('Due in next 30 days'), formatPounds(nextDueAmountAmount)],
        [t('Overdue Payments'), formatPounds(overdueAmount)],
        [t('Repayment Date'), loan.repaymentDate?.format('Do MMMM Y')],
    ];

    return <Box>
        <Paper className={classes.summaryPaper}>
            <Grid container>
                <Grid item xs={8}>
                    {details.map((line, index) => (
                        <Grid key={index} container spacing={2}>
                            {line.map((item, index) => (
                                <Grid key={index} item xs={6}>
                                    {item}
                                </Grid>
                            ))}
                        </Grid>
                    ))}
                </Grid>
                <Grid item xs={4}>
                    <Box display="flex" justifyContent="flex-end">
                        <Chip
                            avatar={isOverdue ? <Error /> : <CheckCircle />}
                            label={isOverdue ? t('Overdue') : t('Up to date')}
                        />
                    </Box>
                </Grid>
            </Grid>

            <PrimaryButton
                onClick={() => handleStatementDownload(loan.loanId || 0)}
                disabled={borrowerStatementDownloading}
                startIcon={<EventNote />}
                className={classes.statementButton}
            >
                {t('Statement')}
            </PrimaryButton>
        </Paper>

        <OverduePayments payments={overduePayments} />

        <NextDuePayments
            payments={nextDuePayments}
            className={classes.nextPaymentsBlock}
        />

        <Grid container direction="row" justify="space-between" className={classes.scheduleTop}>
            <Grid item zeroMinWidth md>
                <Typography variant="h5"><b>{t('Full schedule')}</b></Typography>
                <Typography noWrap>{selectedDrawdown.label}</Typography>
            </Grid>
            {loan.tranches.length > 0 &&
                <Grid item md={5}>
                    <FormControl className={classes.drawdownContainer}>
                        <InputLabel shrink>{t('Choose Drawdown')}</InputLabel>
                        <Select
                            displayEmpty
                            onChange={handleDrawdownChange}
                            value={selectedDrawdownIndex}
                        >
                            <MenuItem disabled value="">
                                {t('Please select from {{count}} drowdowns', { count: drawdowns.length })}
                            </MenuItem>
                            {drawdowns.map((option, index) => <MenuItem key={index} value={index}>{option.label}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
            }
        </Grid>

        <TableContainer>
            <Table>
                <TableHead className={classes.scheduleHead}>
                    <TableRow>
                        {header.map((item, index) => (
                            <TableCell key={index}><b>{item}</b></TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {body.map((row, index) => (
                        <TableRow key={index}>
                            {row.map((cell, index) => (
                                <TableCell key={index}>{cell}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

        <Grid container spacing={2} justify="flex-end" className={classes.scheduleFooter}>
            <Grid item>
                <Typography>{t('Capital Due')}: <b>{formatPounds(calculateCapitalDue(selectedDrawdown.schedule))}</b></Typography>
            </Grid>
            <Grid item>
                <Typography>{t('Interest Due')}: <b>{formatPounds(calculateInterestDue(selectedDrawdown.schedule))}</b></Typography>
            </Grid>
        </Grid>
    </Box>;
};
