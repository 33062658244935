import { Button as MUIButton, ButtonProps, makeStyles } from "@material-ui/core";
import clsx from 'clsx';

const useStyles = makeStyles({
    root: {
        borderRadius: 12,
        borderWidth: 2,
        '&:hover': {
            borderWidth: 2,
        },
    },
});

export const SecondaryButton = <C extends React.ElementType>(props: ButtonProps<C, { component?: C }>) => {
    const { className, ...other } = props;
    const classes = useStyles();

    return <MUIButton
        className={clsx(classes.root, className)}
        variant="outlined"
        color="primary"
        {...other}
    />;
};
