import NumberFormat, { NumberFormatProps } from "react-number-format";

export type NumberInputProps = Omit<NumberFormatProps, "getInputRef"|"onValueChange"> & {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
};

export const NumberInput = (props: NumberInputProps) => {
    const { inputRef, onChange, ...other } = props;

    return <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
            onChange({
                target: {
                    name: props.name,
                    value: values.value,
                },
            });
        }}
    />;
};
