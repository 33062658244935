import { List, ListItem, ListItemIcon, ListItemText, SvgIcon } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";

export interface NavigationItem {
    icon: typeof SvgIcon;
    label: string;
    path: string;
}

export interface NavigationProps {
    navigation: NavigationItem[];
}

export const Navigation = (props: NavigationProps) => {
    const { navigation } = props;
    const history = useHistory();

    return <List>
        {navigation.map(
            ({ path, icon: Icon, label }, index) =>
                <ListItem key={index} button component={Link} to={path} selected={history.location.pathname.startsWith(path)}>
                    <ListItemIcon>
                        <Icon />
                    </ListItemIcon>
                    <ListItemText>{label}</ListItemText>
                </ListItem>,
        )}
    </List>;
};
