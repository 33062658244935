import { AppState } from '../state';

export const getAuthenticatedBorrower = (state: AppState) => state.auth.borrower;
export const getBorrowerLoginInProcess = (state: AppState) => state.auth.borrowerLoginInProcess;
export const getBorrowerLoginFailed = (state: AppState) => state.auth.borrowerLoginFailed;
export const getBorrowerAuthenticationFinished = (state: AppState) => state.auth.borrowerAuthenticationFinished;

export const getAuthenticatedDataRoomUser = (state: AppState) => state.auth.dataRoomUser;
export const getDataRoomLoginInProcess = (state: AppState) => state.auth.dataRoomLoginInProcess;
export const getDataRoomLoginFailed = (state: AppState) => state.auth.dataRoomLoginFailed;
export const getDataRoomAuthenticationFinished = (state: AppState) => state.auth.dataRoomAuthenticationFinished;
