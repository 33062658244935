import { ActionsUnion, createAction } from '../../utils/store';

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILED = 'GET_COUNTRIES_FAILED';

export const SystemActions = {
    getCountries: () => createAction(GET_COUNTRIES),
    getCountriesSuccess: (countries: Record<string, string>) => createAction(GET_COUNTRIES_SUCCESS, { countries }),
    getCountriesFailed: () => createAction(GET_COUNTRIES_FAILED),
};

export type SystemActionsType = ActionsUnion<typeof SystemActions>;
