import { AxiosInstance, AxiosError } from 'axios';
import { apiClient, authClient } from '../utils/api';
import { BorrowerLoanModel } from '../models/loan/BorrowerLoanModel';
import moment from 'moment';
import { storage } from '../utils/storage';
import { StorageKey } from '../constants/StorageKey';
import { StatisticsModel } from '../models/loan/StatisticsModel';
import { BorrowerLoanStatus } from '../constants/BorrowerLoanStatus';
import { DataRoomLoanModel } from '../models/loan/DataRoomLoanModel';
import { DataRoomLoansStatisticsModel } from '../models/loan/DataRoomLoansStatisticsModel';
import { EzmApiErrorCodes } from '../constants/EzmApiErrorCodes';
import { ApiErrorModel } from '../models/ApiErrorModel';

class LoanService {
    constructor(
        private apiClient: AxiosInstance,
        private authClient: AxiosInstance,
    ) {}

    public getBorrowerLoans = async (status: BorrowerLoanStatus, page: number, pageSize: number): Promise<[BorrowerLoanModel[], number]> => {
        const token = storage.get(StorageKey.BORROWER_TOKEN);
        const response = await this.apiClient.get<{ data: BorrowerLoanModel[]; meta: { total: number } }>('/borrowers/loans', {
            headers: {
                'authorization': token && `bearer ${token}`,
            },
            params: { status, page, pageSize },
        });

        return [
            response.data.data.map(item => ({
                ...item,
                repaymentDate: item.repaymentDate && moment(item.repaymentDate),
                schedule: item.schedule.map(schedule => ({
                    ...schedule,
                    dueDate: moment(schedule.dueDate),
                })),
                documents: item.documents.map(document => ({
                    ...document,
                    timestamp: moment(document.timestamp),
                })),
                tranches: item.tranches.map(tranche => ({
                    ...tranche,
                    schedule: tranche.schedule.map(schedule => ({
                        ...schedule,
                        dueDate: moment(schedule.dueDate),
                    })),
                })),
            } as BorrowerLoanModel)),
            response.data.meta.total,
        ];
    };

    public getBorrowerLoan = async (id: number): Promise<BorrowerLoanModel> => {
        const token = storage.get(StorageKey.BORROWER_TOKEN);
        const response = await this.apiClient.get<{ data: BorrowerLoanModel }>(`/borrowers/loans/${id}`, {
            headers: {
                'authorization': token && `bearer ${token}`,
            },
        });

        return {
            ...response.data.data,
            repaymentDate: response.data.data.repaymentDate && moment(response.data.data.repaymentDate),
            schedule: response.data.data.schedule.map(schedule => ({
                ...schedule,
                dueDate: moment(schedule.dueDate),
            })),
            documents: response.data.data.documents.map(document => ({
                ...document,
                timestamp: moment(document.timestamp),
            })),
            tranches: response.data.data.tranches.map(tranche => ({
                ...tranche,
                schedule: tranche.schedule.map(schedule => ({
                    ...schedule,
                    dueDate: moment(schedule.dueDate),
                })),
            })),
        } as BorrowerLoanModel;
    };

    public getStatistics = async (): Promise<StatisticsModel> => {
        const token = storage.get(StorageKey.BORROWER_TOKEN);
        const response = await this.apiClient.get<{ data: StatisticsModel }>(
            '/borrowers/statistics',
            {
                headers: {
                    'authorization': token && `bearer ${token}`,
                },
            },
        );

        return response.data.data;
    };

    public getDataRoomLoans = async (
        page: number, 
        pageSize: number, 
        column?: string|number|symbol,
        order?: string,
    ): Promise<[DataRoomLoanModel[], number]> => {
        const token = storage.get(StorageKey.DATA_ROOM_TOKEN);
        let params = { page, pageSize };

        if (column && order) {
            params = { ...params, ...{ 'order': order, 'column': column } };
        }

        const response = await this.apiClient.get<{ data: DataRoomLoanModel[]; meta: { total: number } }>(
            '/dataroom/loans',
            {
                headers: {
                    'authorization': token && `bearer ${token}`,
                },
                params: params,
            },
        );

        return [
            response.data.data.map(item => ({
                ...item, startDate: moment(item.startDate),
            })),
            response.data.meta.total,
        ];
    };

    public getDataRoomLoansStatistics = async (status?: 'active' | 'pending' | 'completed'): Promise<DataRoomLoansStatisticsModel> => {
        const token = storage.get(StorageKey.DATA_ROOM_TOKEN);

        let params = {};
        if (status) {
            params = {
                'status': status,
            };
        }
        const response = await this.apiClient.get<{ data: DataRoomLoansStatisticsModel }>(
            '/dataroom/loans/statistics',
            {
                headers: {
                    'authorization': token && `bearer ${token}`,
                },
                params:params,
            },
        );

        return response.data.data;
    };

    public getDataRoomLoan = async (id: number): Promise<DataRoomLoanModel> => {
        const token = storage.get(StorageKey.DATA_ROOM_TOKEN);

        const response = await this.apiClient.get<{ data: DataRoomLoanModel }>(
            `/dataroom/loans/${id}`,
            {
                headers: {
                    'authorization': token && `bearer ${token}`,
                },
            },
        );

        return response.data.data;
    };

    public getDropboxLoanLink = async (loanCode: string) => {
        const token = storage.get(StorageKey.DATA_ROOM_TOKEN);

        try {
            const response = await this.apiClient.get<{ data: { link: string } }>(
                '/dataroom/loans/generateDropboxLoanLink',
                {
                    headers: {
                        'authorization': token && `bearer ${token}`,
                    },
                    params: { loanCode },
                },
            );

            return response.data.data;
        } catch (e) {
            if ((e as AxiosError<ApiErrorModel>).response?.data?.error?.code ===
                EzmApiErrorCodes.DROPBOX_LOAN_DIRECTORY_NOT_FOUND) {
                return { link: null };
            }

            throw e;
        }
    };

    public downloadBorrowerLoanStatement = (loanId: number) => {
        const token = storage.get(StorageKey.BORROWER_TOKEN);

        return this.apiClient.get<Blob>(`/borrowers/loans/${loanId}/statement`, {
            headers: {
                'authorization': token && `bearer ${token}`,
            },
            params: {
                type: 'pdf',
            },
            responseType: 'blob',
        });
    };
}

export const loanService = new LoanService(apiClient, authClient);
