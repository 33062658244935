import { Box, FormControlLabel, FormHelperText, makeStyles, Radio, RadioGroup, TextField, Theme, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import { TFunction } from "i18next";
import { ChangeEvent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { NumberInput } from "../../../../components/customInputs/NumberInput";
import { ExitStrategy } from "../../../../constants/ExitStrategy";
import { LoanPurpose } from "../../../../constants/LoanPurpose";
import { LoanType } from "../../../../constants/LoanType";
import { formatPounds } from "../../../../utils/money";
import * as Yup from 'yup';
import { config } from "../../../../config";

const useStyles = makeStyles((theme: Theme) => ({
    section: {
        maxWidth: theme.spacing(68),
        '&:not(:first-child)': {
            marginTop: theme.spacing(4),
        },
    },
    totalAmount: {
        marginTop: theme.spacing(2),
    },
}));

interface LoanPurposeItem {
    value: LoanPurpose;
    label: string;
}

interface Values {
    loanType: string;
    loanPurpose: string;
    loanPurposeOther: string;
    landLoanAmount: string;
    developmentLoanAmount: string;
    buildCosts: string;
    otherCosts: string;
    assetValue: string;
    developmentDescription: string;
    additionalInformation: string;
    loanAmount: string;
    isDiscussed: string;
    loanTerm: string;
    exitStrategy: string;
}

export const loanDetailsValidationSchema = (t: TFunction) => Yup.object().shape({
    loanType: Yup.string().oneOf(['bridge', 'development']).required(t('Please choose option')),
    loanPurpose: Yup.string().oneOf(['purchase', 'refinance', 'capital-raising', 'renovation', 'other']).required(t('Please select loan purpose')),
    loanPurposeOther: Yup.string().when('loanPurpose', { is: 'other', then: Yup.string().required(t('Please enter loan purpose')) }),
    landLoanAmount: Yup.number().when('loanType', { is: 'development', then: Yup.number().required(t('Please enter land loan amount')) }),
    developmentLoanAmount: Yup.number().when('loanType', { is: 'development', then: Yup.number().required(t('Please enter development loan amount')) }),
    buildCosts: Yup.number().when('loanType', { is: 'development', then: Yup.number().required(t('Please enter estimated build costs')) }),
    otherCosts: Yup.number().when('loanType', { is: 'development', then: Yup.number().required(t('Please enter other estimated costs')) }),
    assetValue: Yup.number().when('loanType', { is: 'development', then: Yup.number().required(t('Please enter potential gross development value')) }),
    developmentDescription: Yup.string().when('loanType', { is: 'development', then: Yup.string().required(t('Please provide a brief description')) }),
    additionalInformation: Yup.string().when('loanType', { is: 'development', then: Yup.string().required(t('Please provide any additional information we should consider')) }),
    loanAmount: Yup.number()
        .required(t('Please enter loan amount'))
        .min(
            config.loanApplication.minimumLoanAmount,
            (options) => t('The minimum loan amount is {{min, currency(currency: GBP; maximumFractionDigits: 0)}}', options),
        ),
    isDiscussed: Yup.boolean().required(t('Please choose option')),
    loanTerm: Yup.number()
        .required(t('Please enter loan term'))
        .max(
            config.loanApplication.maximumLoanTerm,
            (options) => t('The maximum loan term is {{max}} months', options),
        ),
    exitStrategy: Yup.string().oneOf(['refinance', 'sale']).required(t('Please select exit strategy')),
});

export const loanDetailsInitialValues = {
    loanType: '',
    loanPurpose: '',
    loanPurposeOther: '',
    landLoanAmount: '',
    developmentLoanAmount: '',
    buildCosts: '',
    otherCosts: '',
    assetValue: '',
    developmentDescription: '',
    additionalInformation: '',
    loanAmount: '',
    isDiscussed: '',
    loanTerm: '',
    exitStrategy: '',
};

export const LoanDetailsStep = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const formik = useFormikContext<Values>();

    const loanPurposes: LoanPurposeItem[] = [
        {
            value: LoanPurpose.Purchase,
            label: t('Purchase'),
        },
        {
            value: LoanPurpose.Refinance,
            label: t('Refinance'),
        },
        {
            value: LoanPurpose.CapitalRaising,
            label: t('Capital Raising'),
        },
        {
            value: LoanPurpose.Renovation,
            label: t('Renovation'),
        },
    ];

    const handleLoanPurposeChange = (e: ChangeEvent<HTMLInputElement>) => {
        formik.handleChange(e);
        formik.setFieldValue('loanPurposeOther', '');
    };

    const handleLoanTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
        formik.handleChange(e);

        if (e.target.value === LoanType.Bridge) {
            formik.setFieldValue('landLoanAmount', '');
            formik.setFieldValue('developmentLoanAmount', '');
            formik.setFieldValue('buildCosts', '');
            formik.setFieldValue('otherCosts', '');
            formik.setFieldValue('assetValue', '');
            formik.setFieldValue('loanAmount', '');
        } else {
            formik.setFieldValue('loanAmount', '');
        }
    };

    const handleLandLoanAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
        formik.handleChange(e);
        formik.setFieldValue('loanAmount', String(Number(e.target.value) + Number(formik.values.developmentLoanAmount)));
    };

    const handleDevelopmentLoanAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
        formik.handleChange(e);
        formik.setFieldValue('loanAmount', String(Number(e.target.value) + Number(formik.values.landLoanAmount)));
    };

    const totalCosts = formatPounds(Number(formik.values.buildCosts) + Number(formik.values.otherCosts));

    return <Box>
        <Box className={classes.section}>
            <Typography gutterBottom variant="h5">
                <b>{t('Why is the loan required?')}</b>
            </Typography>
            <RadioGroup
                name="loanPurpose"
                value={formik.values.loanPurpose}
                onChange={handleLoanPurposeChange}
                onBlur={formik.handleBlur}
            >
                {loanPurposes.map(
                    item => <FormControlLabel
                        key={item.value}
                        value={item.value}
                        control={<Radio color="primary" />}
                        label={item.label}
                    />,
                )}
                <FormControlLabel
                    value={LoanPurpose.Other}
                    control={<Radio color="primary" />}
                    label={t('Other')}
                />
                { formik.values.loanPurpose === LoanPurpose.Other &&
                    <TextField
                        fullWidth
                        name="loanPurposeOther"
                        label={t('Please specify')}
                        placeholder={t('Please enter the loan purpose')}
                        value={formik.values.loanPurposeOther}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        error={formik.touched.loanPurposeOther && Boolean(formik.errors.loanPurposeOther)}
                        helperText={formik.touched.loanPurposeOther && formik.errors.loanPurposeOther}
                    />
                }
            </RadioGroup>
            <FormHelperText error={formik.touched.loanPurpose && Boolean(formik.errors.loanPurpose)}>
                {formik.touched.loanPurpose && formik.errors.loanPurpose}
            </FormHelperText>
        </Box>

        <Box className={classes.section}>
            <Typography gutterBottom variant="h5">
                <b>{t('Is development finance also required?')}</b>
            </Typography>
            <RadioGroup
                name="loanType"
                value={formik.values.loanType}
                onChange={handleLoanTypeChange}
                onBlur={formik.handleBlur}
                row
            >
                <FormControlLabel
                    value={LoanType.Development}
                    control={<Radio color="primary" />}
                    label={t('Yes')}
                />
                <FormControlLabel
                    value={LoanType.Bridge}
                    control={<Radio color="primary" />}
                    label={t('No')}
                />
            </RadioGroup>
            <FormHelperText error={formik.touched.loanType && Boolean(formik.errors.loanType)}>
                {formik.touched.loanType && formik.errors.loanType}
            </FormHelperText>
        </Box>

        { formik.values.loanType === LoanType.Bridge &&
            <Box className={classes.section}>
                <Typography gutterBottom variant="h5">
                    <b>{t('Loan amount required')}</b>
                </Typography>
                <TextField
                    fullWidth
                    name="loanAmount"
                    label={t('Value')}
                    placeholder={t('Please enter loan amount required')}
                    value={formik.values.loanAmount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    error={formik.touched.loanAmount && Boolean(formik.errors.loanAmount)}
                    helperText={formik.touched.loanAmount && formik.errors.loanAmount}
                    InputProps={{
                        inputComponent: NumberInput as never,
                    }}
                    inputProps={{
                        decimalScale: 2,
                        allowNegative: false,
                        thousandSeparator: ',',
                        isNumericString: true,
                        prefix: '£',
                    }}
                />
            </Box>
        }

        { formik.values.loanType === LoanType.Development &&
            <Fragment>
                <Box className={classes.section}>
                    <Typography gutterBottom variant="h5">
                        <b>{t('Loan amount required')}</b>
                    </Typography>
                    <TextField
                        fullWidth
                        name="landLoanAmount"
                        label={t('Land Loan Required')}
                        placeholder={t('Please enter land loan amount required')}
                        value={formik.values.landLoanAmount}
                        onChange={handleLandLoanAmountChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        error={formik.touched.landLoanAmount && Boolean(formik.errors.landLoanAmount)}
                        helperText={formik.touched.landLoanAmount && formik.errors.landLoanAmount}
                        InputProps={{
                            inputComponent: NumberInput as never,
                        }}
                        inputProps={{
                            decimalScale: 2,
                            allowNegative: false,
                            thousandSeparator: ',',
                            isNumericString: true,
                            prefix: '£',
                        }}
                    />
                    <TextField
                        fullWidth
                        name="developmentLoanAmount"
                        label={t('Development Loan Required')}
                        placeholder={t('Please enter development loan amount required')}
                        value={formik.values.developmentLoanAmount}
                        onChange={handleDevelopmentLoanAmountChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        error={formik.touched.developmentLoanAmount && Boolean(formik.errors.developmentLoanAmount)}
                        helperText={formik.touched.developmentLoanAmount && formik.errors.developmentLoanAmount}
                        InputProps={{
                            inputComponent: NumberInput as never,
                        }}
                        inputProps={{
                            decimalScale: 2,
                            allowNegative: false,
                            thousandSeparator: ',',
                            isNumericString: true,
                            prefix: '£',
                        }}
                    />
                    <Typography className={classes.totalAmount}>
                        <b>{t('Total loan required')}:</b> {formatPounds(Number(formik.values.loanAmount))}
                    </Typography>
                    <FormHelperText error={formik.touched.landLoanAmount && formik.touched.developmentLoanAmount && Boolean(formik.errors.loanAmount)}>
                        {formik.touched.landLoanAmount && formik.touched.developmentLoanAmount && formik.errors.loanAmount}
                    </FormHelperText>
                </Box>
                <Box className={classes.section}>
                    <Typography gutterBottom variant="h5">
                        <b>{t('Estimated development costs')}</b>
                    </Typography>
                    <TextField
                        fullWidth
                        name="buildCosts"
                        label={t('Build Costs')}
                        placeholder={t('Please enter potential build costs')}
                        value={formik.values.buildCosts}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        error={formik.touched.buildCosts && Boolean(formik.errors.buildCosts)}
                        helperText={formik.touched.buildCosts && formik.errors.buildCosts}
                        InputProps={{
                            inputComponent: NumberInput as never,
                        }}
                        inputProps={{
                            decimalScale: 2,
                            allowNegative: false,
                            thousandSeparator: ',',
                            isNumericString: true,
                            prefix: '£',
                        }}
                    />
                    <TextField
                        fullWidth
                        name="otherCosts"
                        label={t('Other Costs')}
                        placeholder={t('Please enter other potential costs')}
                        value={formik.values.otherCosts}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        error={formik.touched.otherCosts && Boolean(formik.errors.otherCosts)}
                        helperText={formik.touched.otherCosts && formik.errors.otherCosts}
                        InputProps={{
                            inputComponent: NumberInput as never,
                        }}
                        inputProps={{
                            decimalScale: 2,
                            allowNegative: false,
                            thousandSeparator: ',',
                            isNumericString: true,
                            prefix: '£',
                        }}
                    />

                    <Typography className={classes.totalAmount}>
                        <b>{t('Total costs')}:</b> {totalCosts}
                    </Typography>
                </Box>
                <Box className={classes.section}>
                    <Typography gutterBottom variant="h5">
                        <b>{t('Estimated development value')}</b>
                    </Typography>
                    <TextField
                        fullWidth
                        name="assetValue"
                        label={t('Potential gross development value')}
                        placeholder={t('Please enter potential gross development value')}
                        value={formik.values.assetValue}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        error={formik.touched.assetValue && Boolean(formik.errors.assetValue)}
                        helperText={formik.touched.assetValue && formik.errors.assetValue}
                        InputProps={{
                            inputComponent: NumberInput as never,
                        }}
                        inputProps={{
                            decimalScale: 2,
                            allowNegative: false,
                            thousandSeparator: ',',
                            isNumericString: true,
                            prefix: '£',
                        }}
                    />
                </Box>
                <Box className={classes.section}>
                    <Typography gutterBottom variant="h5">
                        <b>{t('Description of development opportunity')}</b>
                    </Typography>
                    <TextField
                        multiline
                        fullWidth
                        name="developmentDescription"
                        placeholder={t('Please provide a brief description')}
                        value={formik.values.developmentDescription}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        rows={5}
                        inputProps={{ maxLength: 2000 }}
                        error={formik.touched.developmentDescription && Boolean(formik.errors.developmentDescription)}
                        helperText={formik.touched.developmentDescription && formik.errors.developmentDescription}
                    />
                </Box>
                <Box className={classes.section}>
                    <Typography gutterBottom variant="h5">
                        <b>{t('Any additional information we should consider?')}</b>
                    </Typography>
                    <TextField
                        multiline
                        fullWidth
                        name="additionalInformation"
                        placeholder={t('Please provide additional information')}
                        value={formik.values.additionalInformation}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        rows={5}
                        inputProps={{ maxLength: 1000 }}
                        error={formik.touched.additionalInformation && Boolean(formik.errors.additionalInformation)}
                        helperText={formik.touched.additionalInformation && formik.errors.additionalInformation}
                    />
                </Box>
            </Fragment>
        }

        <Box className={classes.section}>
            <Typography gutterBottom variant="h5">
                <b>{t('Have you already discussed this case with us?')}</b>
            </Typography>
            <RadioGroup
                name="isDiscussed"
                value={formik.values.isDiscussed}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                row
            >
                <FormControlLabel
                    value="true"
                    control={<Radio color="primary" />}
                    label={t('Yes')}
                />
                <FormControlLabel
                    value="false"
                    control={<Radio color="primary" />}
                    label={t('No')}
                />
            </RadioGroup>
            <FormHelperText error={formik.touched.isDiscussed && Boolean(formik.errors.isDiscussed)}>
                {formik.touched.isDiscussed && formik.errors.isDiscussed}
            </FormHelperText>
        </Box>

        <Box className={classes.section}>
            <Typography gutterBottom variant="h5">
                <b>{t('Loan term required')}</b>
            </Typography>
            <TextField
                fullWidth
                name="loanTerm"
                placeholder={t('Please enter loan term required')}
                value={formik.values.loanTerm}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="dense"
                error={formik.touched.loanTerm && Boolean(formik.errors.loanTerm)}
                helperText={formik.touched.loanTerm && formik.errors.loanTerm}
                InputProps={{
                    inputComponent: NumberInput as never,
                }}
                inputProps={{
                    decimalScale: 0,
                    allowNegative: false,
                    isNumericString: true,
                }}
            />
        </Box>

        <Box className={classes.section}>
            <Typography gutterBottom variant="h5">
                <b>{t('How do you intend to repay the loan?')}</b>
            </Typography>
            <RadioGroup
                name="exitStrategy"
                value={formik.values.exitStrategy}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            >
                <FormControlLabel
                    value={ExitStrategy.Refinance}
                    control={<Radio color="primary" />}
                    label={t('Refinance')}
                />
                <FormControlLabel
                    value={ExitStrategy.Sale}
                    control={<Radio color="primary" />}
                    label={t('Sale')}
                />
            </RadioGroup>
            <FormHelperText error={formik.touched.exitStrategy && Boolean(formik.errors.exitStrategy)}>
                {formik.touched.exitStrategy && formik.errors.exitStrategy}
            </FormHelperText>
        </Box>
    </Box>;
};
