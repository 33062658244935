import { all, call, put, takeLatest } from 'redux-saga/effects';
import { systemService } from '../../services/systemService';
import { GET_COUNTRIES, SystemActions } from './SystemActions';

function* getCountries() {
    try {
        const countries: Awaited<ReturnType<typeof systemService.getCountries>> = yield call(systemService.getCountries);

        yield put(SystemActions.getCountriesSuccess(countries));
    } catch (e) {
        yield put(SystemActions.getCountriesFailed());
    }
}

export function* systemSaga() {
    yield all([
        takeLatest(GET_COUNTRIES, getCountries),
    ]);
}
