import { Fragment } from "react";
import { CurrentLoans } from "../../containers/Borrower/MyLoans/CurrentLoans";
import { DeclinedLoans } from "../../containers/Borrower/MyLoans/DeclinedLoans";
import { PendingLoans } from "../../containers/Borrower/MyLoans/PendingLoans";
import { PreviousLoans } from "../../containers/Borrower/MyLoans/PreviousLoans";

export const MyLoans = () => {
    return <Fragment>
        <PendingLoans />
        <DeclinedLoans />
        <CurrentLoans />
        <PreviousLoans />
    </Fragment>;
};
