import { Button as MUIButton, ButtonProps, makeStyles } from "@material-ui/core";
import clsx from 'clsx';

const useStyles = makeStyles({
    root: {
        borderRadius: 12,
    },
});

export const PrimaryButton = <C extends React.ElementType>(props: ButtonProps<C, { component?: C }>) => {
    const { className, ...other } = props;
    const classes = useStyles();

    return <MUIButton
        className={clsx(classes.root, className)}
        variant="contained"
        color="primary"
        {...other}
    />;
};
