import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Login as BorrowerLogin } from './pages/Borrower/Login';
import { Login as DataRoomLogin } from './pages/DataRoom/Login';
import { Register } from './pages/Borrower/Register';
import { RegisterDone } from './pages/Borrower/RegisterDone';
import { BorrowerRoute } from "./components/BorrowerRoute";
import { Dashboard } from "./pages/Borrower/Dashboard";
import { DataRoomLoans } from './pages/DataRoom/DataRoomLoans';
import { DataRoomRoute } from './components/DataRoomRoute';
import { DataRoomLoan } from './pages/DataRoom/DataRoomLoan';
import { MyLoans } from './pages/Borrower/MyLoans';
import { ApplyForLoan } from './pages/Borrower/ApplyForLoan';
import { ApplyForLoanDone } from './pages/Borrower/ApplyForLoanDone';
import { Loan } from './pages/Borrower/Loan';
import { ScrollToTop } from './components/ScrollToTop';
import { Logout as BorrowerLogout } from './pages/Borrower/Logout';
import { Logout as DataRoomLogout } from './pages/DataRoom/Logout';
import { NotFound } from './pages/NotFound';
import { Layout as BorrowerLayout } from './containers/Borrower/Common/Layout';
import { Layout as DataRoomLayout } from './containers/DataRoom/Common/Layout';
import { Statistics } from './pages/DataRoom/Statistics';

export const Router = () => {
    return <BrowserRouter>
        <ScrollToTop />
        <Switch>
            <Route exact path="/">
                <Redirect to="/register" />
            </Route>
            <Route exact path="/login">
                <BorrowerLogin />
            </Route>
            <Route exact path="/dataroom/login">
                <DataRoomLogin />
            </Route>
            <Route exact path="/register">
                <Register />
            </Route>
            <Route exact path="/register/done">
                <RegisterDone />
            </Route>

            <BorrowerRoute exact path="/dashboard">
                <BorrowerLayout>
                    <Dashboard />
                </BorrowerLayout>
            </BorrowerRoute>
            <BorrowerRoute exact path="/loans">
                <BorrowerLayout>
                    <MyLoans />
                </BorrowerLayout>
            </BorrowerRoute>
            <BorrowerRoute exact path="/loans/:id">
                <BorrowerLayout>
                    <Loan />
                </BorrowerLayout>
            </BorrowerRoute>
            <BorrowerRoute exact path="/apply-for-loan">
                <BorrowerLayout>
                    <ApplyForLoan />
                </BorrowerLayout>
            </BorrowerRoute>
            <BorrowerRoute exact path="/apply-for-loan/done">
                <BorrowerLayout>
                    <ApplyForLoanDone />
                </BorrowerLayout>
            </BorrowerRoute>
            <BorrowerRoute exact path="/logout">
                <BorrowerLayout>
                    <BorrowerLogout />
                </BorrowerLayout>
            </BorrowerRoute>

            <DataRoomRoute path="/statistics">
                <DataRoomLayout>
                    <Statistics />
                </DataRoomLayout>
            </DataRoomRoute>
            <DataRoomRoute path="/dataroom/loans/:id">
                <DataRoomLayout>
                    <DataRoomLoan />
                </DataRoomLayout>
            </DataRoomRoute>
            <DataRoomRoute path="/dataroom/loans">
                <DataRoomLayout>
                    <DataRoomLoans />
                </DataRoomLayout>
            </DataRoomRoute>
            <DataRoomRoute exact path="/dataroom/logout">
                <DataRoomLayout>
                    <DataRoomLogout />
                </DataRoomLayout>
            </DataRoomRoute>

            <Route path="*">
                <NotFound />
            </Route>
        </Switch>
    </BrowserRouter>;
};
