import { Grid, makeStyles, Typography } from "@material-ui/core";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Header } from "../containers/Common/Header";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(16),
    },
}));

export const NotFound = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return <Fragment>
        <Header />
        <Grid container justify="center" alignItems="center" className={classes.container}>
            <Typography variant="h4" color="textSecondary" align="center">
                {t('Sorry, the page you are looking for was not found')}
            </Typography>
        </Grid>
    </Fragment>;
};
