import { Fragment } from "react";
import { LoanApplicationSubmitted } from "../../containers/Borrower/Register/LoanApplicationSubmitted";
import { Header } from "../../containers/Common/Header";

export const RegisterDone = () => {
    return <Fragment>
        <Header />
        <LoanApplicationSubmitted />
    </Fragment>;
};
