export const config = {
    environment: process.env.NODE_ENV,
    appUrl: process.env.REACT_APP_URL || 'http://127.0.0.1:3000/',
    apiUrl: process.env.REACT_APP_API_URL || 'http://127.0.0.1:8081/api/',
    authUrl: process.env.REACT_APP_AUTH_URL || 'http://127.0.0.1:8085/',
    marketingUrl: process.env.REACT_APP_MARKETING_URL || 'https://easymoney.com/',
    reCaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    dropboxAppKey: process.env.REACT_APP_DROPBOX_APP_KEY || '3y2qwaem0re2fmd',
    loanApplication: {
        maximumLoanTerm: 60,
        minimumLoanAmount: 30000,
        minimumAssetValue: 150000,
    },
};
