import { BorrowerLoanStatus } from "../../constants/BorrowerLoanStatus";
import { getBorrowerLoanKey } from "../../utils/store";
import { AppState } from "../state";

export const getPendingBorrowerLoans = (state: AppState) => Object.values(state.loan.borrowerLoans)
    .filter(loan => loan.status === BorrowerLoanStatus.Pending);
export const getPendingBorrowerLoansPaginated = (page: number) => (state: AppState) =>
    (state.loan.pendingBorrowerLoansPagination[page] || []).map(key => state.loan.borrowerLoans[key]);
export const getPendingBorrowerLoansLoading = (state: AppState) => state.loan.pendingBorrowerLoansLoading;
export const getPendingBorrowerLoansCount = (state: AppState) => state.loan.pendingBorrowerLoansCount;

export const getDeclinedBorrowerLoans = (state: AppState) => Object.values(state.loan.borrowerLoans)
    .filter(loan => loan.status === BorrowerLoanStatus.Declined);
export const getDeclinedBorrowerLoansPaginated = (page: number) => (state: AppState) =>
    (state.loan.declinedBorrowerLoansPagination[page] || []).map(key => state.loan.borrowerLoans[key]);
export const getDeclinedBorrowerLoansLoading = (state: AppState) => state.loan.declinedBorrowerLoansLoading;
export const getDeclinedBorrowerLoansCount = (state: AppState) => state.loan.declinedBorrowerLoansCount;

export const getActiveBorrowerLoans = (state: AppState) => Object.values(state.loan.borrowerLoans)
    .filter(loan => loan.status === BorrowerLoanStatus.Active);
export const getActiveBorrowerLoansPaginated = (page: number) => (state: AppState) =>
    (state.loan.activeBorrowerLoansPagination[page] || []).map(key => state.loan.borrowerLoans[key]);
export const getActiveBorrowerLoansLoading = (state: AppState) => state.loan.activeBorrowerLoansLoading;
export const getActiveBorrowerLoansCount = (state: AppState) => state.loan.activeBorrowerLoansCount;

export const getCompletedBorrowerLoans = (state: AppState) => Object.values(state.loan.borrowerLoans)
    .filter(loan => loan.status === BorrowerLoanStatus.Completed);
export const getCompletedBorrowerLoansPaginated = (page: number) => (state: AppState) =>
    (state.loan.completedBorrowerLoansPagination[page] || []).map(key => state.loan.borrowerLoans[key]);
export const getCompletedBorrowerLoansLoanding = (state: AppState) => state.loan.completedBorrowerLoansLoading;
export const getCompletedBorrowerLoansCount = (state: AppState) => state.loan.completedBorrowerLoansCount;

export const getBorrowerLoanById = (loanId: number|null, loanApplicationId: number|null) => (state: AppState) => state.loan.borrowerLoans[getBorrowerLoanKey(loanId, loanApplicationId)];

export const getStatistics = (state: AppState) => state.loan.statistics;
export const getStatisticsLoading = (state: AppState) => state.loan.statisticsLoading;

export const getBorrowerLoanLoading = (state: AppState) => state.loan.borrowerLoanLoading;

export const getDataRoomLoans = (state: AppState) => Object.values(
    state.loan.dataRoomLoans,
);
export const getDataRoomLoansPaginated = (page: number) => (state: AppState) =>
    (state.loan.dataRoomLoansPagination[page] || []).map(
        key => state.loan.dataRoomLoans[key],
    );
export const getDataRoomLoansLoading = (state: AppState) => state.loan.dataRoomLoansLoading;
export const getDataRoomLoansTotal = (state: AppState) => state.loan.dataRoomLoansCount;

export const getDataRoomLoansStatistics = (state: AppState) => state.loan.dataRoomLoansStatistics;
export const getDataRoomLoansStatisticsLoading = (state: AppState) => state.loan.dataRoomLoansStatisticsLoading;

export const getDataRoomLoanLoading = (state: AppState) => state.loan.dataRoomLoanLoading;
export const getDataRoomLoanById = (id: number) => (state: AppState) => state.loan.dataRoomLoans[id];

export const getDropboxLoanLinkByLoanCode = (loanCode: string) => (state: AppState) => state.loan.dropboxLoanLinks[loanCode] || null;
export const getDropboxLoanLinkLoading = (state: AppState) => state.loan.dropboxLoanLinkLoading;

export const getBorrowerLoanStatementDownloading = (state: AppState) => state.loan.borrowerLoanStatementDownloading;
