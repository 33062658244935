import { RegisterUserModel } from '../../models/auth/RegisterUserModel';
import { CreateLoanApplicationModel } from '../../models/loanApplication/CreateLoanApplicationModel';
import { LoanApplicationModel } from '../../models/loanApplication/LoanApplicationModel';
import { ActionsUnion, createAction } from '../../utils/store';

export const CREATE_LOAN_APPLICATION = 'CREATE_LOAN_APPLICATION';
export const CREATE_LOAN_APPLICATION_SUCCESS = 'CREATE_LOAN_APPLICATION_SUCCESS';
export const CREATE_LOAN_APPLICATION_FAILED = 'CREATE_LOAN_APPLICATION_FAILED';

export const SUBMIT_LOAN_APPLICATION = 'SUBMIT_LOAN_APPLICATION';
export const SUBMIT_LOAN_APPLICATION_SUCCESS = 'SUBMIT_LOAN_APPLICATION_SUCCESS';
export const SUBMIT_LOAN_APPLICATION_FAILED = 'SUBMIT_LOAN_APPLICATION_FAILED';

export const GET_LOAN_APPLICATIONS = 'GET_LOAN_APPLICATIONS';
export const GET_LOAN_APPLICATIONS_SUCCESS = 'GET_LOAN_APPLICATIONS_SUCCESS';
export const GET_LOAN_APPLICATIONS_FAILED = 'GET_LOAN_APPLICATIONS_FAILED';

export const LoanApplicationActions = {
    createLoanApplication: (loanApplicationData: CreateLoanApplicationModel) => createAction(CREATE_LOAN_APPLICATION, loanApplicationData),
    createLoanApplicationSuccess: (loanApplicationId: number) => createAction(CREATE_LOAN_APPLICATION_SUCCESS, { loanApplicationId }),
    createLoanApplicationFailed: () => createAction(CREATE_LOAN_APPLICATION_FAILED),

    submitLoanApplication: (loanApplicationData: CreateLoanApplicationModel, applicantData: RegisterUserModel) =>
        createAction(SUBMIT_LOAN_APPLICATION, { loanApplicationData, applicantData }),
    submitLoanApplicationSuccess: () => createAction(SUBMIT_LOAN_APPLICATION_SUCCESS),
    submitLoanApplicationFailed: () => createAction(SUBMIT_LOAN_APPLICATION_FAILED),

    getLoanApplications: (page: number, pageSize: number) => createAction(GET_LOAN_APPLICATIONS, { page, pageSize }),
    getLoanApplicationsSuccess: (loanApplications: LoanApplicationModel[], total: number, page: number) =>
        createAction(GET_LOAN_APPLICATIONS_SUCCESS, { loanApplications, total, page }),
    getLoanApplicationsFailed: () => createAction(GET_LOAN_APPLICATIONS_FAILED),
};

export type LoanApplicationActionsType = ActionsUnion<typeof LoanApplicationActions>;
