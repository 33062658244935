import { Backdrop, CircularProgress } from "@material-ui/core";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useQuery } from "../../hooks/useQuery";
import { AuthActions } from "../../store/auth/AuthActions";
import { getAuthenticatedDataRoomUser, getDataRoomLoginFailed, getDataRoomLoginInProcess } from "../../store/auth/AuthSelectors";
import { appUrl, authUrl } from "../../utils/url";

export const Login = () => {
    const history = useHistory();
    const query = useQuery();
    const dispatch = useDispatch();

    const token = query.get('token');
    const refreshToken = query.get('refreshToken');
    const path = query.get('path');

    const user = useSelector(getAuthenticatedDataRoomUser);
    const dataRoomLoginInProcess = useSelector(getDataRoomLoginInProcess);
    const dataRoomLoginFailed = useSelector(getDataRoomLoginFailed);

    const redirectToLogin = useCallback(() => {
        window.location.replace(authUrl(
            '/login/dataroom',
            {
                offlineAccess: 'true',
                'redirect_url': appUrl(history.location.pathname, path ? { path } : {}),
            },
        ));
    }, [history.location.pathname, path]);

    useEffect(() => {
        if (token && refreshToken) {
            dispatch(AuthActions.dataRoomLogin(token, refreshToken));
        } else {
            redirectToLogin();
        }
    }, [dispatch, redirectToLogin, token, refreshToken]);

    useEffect(() => {
        if (user !== null) {
            history.push(path || '/statistics');
        }
    }, [history, user, path]);

    useEffect(() => {
        if (dataRoomLoginFailed && !dataRoomLoginInProcess) {
            redirectToLogin();
        }
    }, [redirectToLogin, dataRoomLoginInProcess, dataRoomLoginFailed]);

    return <Backdrop open>
        <CircularProgress />
    </Backdrop>;
};
