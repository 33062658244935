import { Box, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Payment, PaymentsTable } from "./PaymentsTable";

interface OverduePaymentsProps {
    payments: Payment[];
    className?: string;
}

const useStyles = makeStyles((theme) => ({
    tableMargin: {
        marginTop: theme.spacing(1),
    },
}));

export const OverduePayments = ({ payments, className }: OverduePaymentsProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return <Box className={className}>
        <Typography variant="h5">{t('Overdue')}</Typography>
        {payments.length > 0
            ? <PaymentsTable payments={payments} className={classes.tableMargin} />
            : <Typography>{'There are no overdue payments'}</Typography>
        }
    </Box>;
};