import { all, call, put, takeLatest } from 'redux-saga/effects';
import { LoanApplicationActions, CREATE_LOAN_APPLICATION, SUBMIT_LOAN_APPLICATION, GET_LOAN_APPLICATIONS } from './LoanApplicationActions';
import { loanApplicationService } from '../../services/loanApplicationService';
import { authService } from '../../services/authService';
import { storage } from '../../utils/storage';
import { StorageKey } from '../../constants/StorageKey';

function* createLoanApplication(action: ReturnType<typeof LoanApplicationActions.createLoanApplication>) {
    try {
        const loanApplicationData = action.payload;

        const loanApplicationId: Awaited<ReturnType<typeof loanApplicationService.create>> =
            yield call(loanApplicationService.create, loanApplicationData);

        yield put(LoanApplicationActions.createLoanApplicationSuccess(loanApplicationId));
    } catch (e) {
        yield put(LoanApplicationActions.createLoanApplicationFailed());
    }
}

function* submitLoanApplication(action: ReturnType<typeof LoanApplicationActions.submitLoanApplication>) {
    try {
        const { applicantData, loanApplicationData } = action.payload;

        const jwt: Awaited<ReturnType<typeof authService.register>> = yield call(authService.register, applicantData);
        storage.put(StorageKey.BORROWER_TOKEN, jwt);
        yield call(loanApplicationService.create, loanApplicationData);

        yield put(LoanApplicationActions.submitLoanApplicationSuccess());
    } catch (e) {
        yield put(LoanApplicationActions.submitLoanApplicationFailed());
    }
}

function* getLoanApplications(action: ReturnType<typeof LoanApplicationActions.getLoanApplications>) {
    try {
        const { page, pageSize } = action.payload;
        const [loanApplications, total]: Awaited<ReturnType<typeof loanApplicationService.get>> =
            yield call(loanApplicationService.get, page, pageSize);
        yield put(LoanApplicationActions.getLoanApplicationsSuccess(loanApplications, total, page));
    } catch (e) {
        yield put(LoanApplicationActions.getLoanApplicationsFailed());
    }
}

export function* loanApplicationSaga() {
    yield all([
        takeLatest(CREATE_LOAN_APPLICATION, createLoanApplication),
        takeLatest(SUBMIT_LOAN_APPLICATION, submitLoanApplication),
        takeLatest(GET_LOAN_APPLICATIONS, getLoanApplications),
    ]);
}
