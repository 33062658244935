import { AxiosInstance } from 'axios';
import { apiClient, authClient } from '../utils/api';
import { CreateLoanApplicationModel } from '../models/loanApplication/CreateLoanApplicationModel';
import { storage } from '../utils/storage';
import { StorageKey } from '../constants/StorageKey';
import { LoanApplicationModel } from '../models/loanApplication/LoanApplicationModel';
import moment from 'moment';

class LoanApplicationService {
    constructor(
        private apiClient: AxiosInstance,
        private authClient: AxiosInstance,
    ) {}

    public create = async (loanApplicationData: CreateLoanApplicationModel): Promise<number> => {
        const token = storage.get(StorageKey.BORROWER_TOKEN);
        const response = await this.apiClient.post<{ data: { loanApplicationId: number } }>(
            '/borrowers/loan-applications',
            loanApplicationData,
            {
                headers: {
                    'authorization': token && `bearer ${token}`,
                },
            },
        );

        return response.data.data.loanApplicationId;
    };

    public get = async (page: number, pageSize: number): Promise<[LoanApplicationModel[], number]> => {
        const token = storage.get(StorageKey.BORROWER_TOKEN);
        const response = await this.apiClient.get<{ data: LoanApplicationModel[]; meta: { total: number } }>(
            '/borrowers/loan-applications',
            {
                headers: {
                    'authorization': token && `bearer ${token}`,
                },
                params: { page, pageSize },
            },
        );

        return [
            response.data.data.map(item => ({
                ...item,
                createdAt: moment(item.createdAt),
            })),
            response.data.meta.total,
        ];
    };
}

export const loanApplicationService = new LoanApplicationService(apiClient, authClient);
