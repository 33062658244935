import {
    AuthActionsType,
    AUTHENTICATE_BORROWER_FAILED,
    AUTHENTICATE_BORROWER_SUCCESS,
    AUTHENTICATE_DATA_ROOM_USER_FAILED,
    AUTHENTICATE_DATA_ROOM_USER_SUCCESS,
    BORROWER_LOGIN,
    BORROWER_LOGIN_FAILED,
    BORROWER_LOGIN_SUCCESS,
    BORROWER_LOGOUT_SUCCESS,
    DATA_ROOM_LOGIN,
    DATA_ROOM_LOGIN_FAILED,
    DATA_ROOM_LOGIN_SUCCESS,
    DATA_ROOM_LOGOUT_SUCCESS,
} from './AuthActions';
import { AuthState } from './AuthState';

export const authInitialState: AuthState = {
    borrower: null,
    borrowerLoginInProcess: false,
    borrowerLoginFailed: false,
    borrowerAuthenticationFinished: false,
    dataRoomUser: null,
    dataRoomLoginInProcess: false,
    dataRoomLoginFailed: false,
    dataRoomAuthenticationFinished: false,
};

export const authReducer = (state = authInitialState, action: AuthActionsType): AuthState => {
    switch (action.type) {
        case BORROWER_LOGIN:
            return {
                ...state,
                borrowerLoginInProcess: true,
                borrowerLoginFailed: false,
            };
        case BORROWER_LOGIN_SUCCESS:
            return {
                ...state,
                borrower: action.payload.borrower,
                borrowerLoginInProcess: false,
            };
        case BORROWER_LOGIN_FAILED:
            return {
                ...state,
                borrowerLoginInProcess: false,
                borrowerLoginFailed: true,
            };

        case BORROWER_LOGOUT_SUCCESS:
            return {
                ...state,
                borrower: null,
            };

        case AUTHENTICATE_BORROWER_SUCCESS:
            return {
                ...state,
                borrower: action.payload.borrower,
                borrowerAuthenticationFinished: true,
            };
        case AUTHENTICATE_BORROWER_FAILED:
            return {
                ...state,
                borrowerAuthenticationFinished: true,
            };

        case DATA_ROOM_LOGIN:
            return {
                ...state,
                dataRoomLoginInProcess: true,
                dataRoomLoginFailed: false,
            };
        case DATA_ROOM_LOGIN_SUCCESS:
            return {
                ...state,
                dataRoomUser: action.payload.user,
                dataRoomLoginInProcess: false,
            };
        case DATA_ROOM_LOGIN_FAILED:
            return {
                ...state,
                dataRoomLoginInProcess: false,
                dataRoomLoginFailed: true,
            };

        case DATA_ROOM_LOGOUT_SUCCESS:
            return {
                ...state,
                dataRoomUser: null,
            };

        case AUTHENTICATE_DATA_ROOM_USER_SUCCESS:
            return {
                ...state,
                dataRoomUser: action.payload.user,
                dataRoomAuthenticationFinished: true,
            };
        case AUTHENTICATE_DATA_ROOM_USER_FAILED:
            return {
                ...state,
                dataRoomAuthenticationFinished: true,
            };

        default:
            return state;
    }
};
