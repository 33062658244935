import { AxiosResponse } from "axios";
import { CONTENT_DISPOSITION } from "../constants/RegExp";

export const getFileNameFromContentDisposition = (disposition: string) => {
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const matches = CONTENT_DISPOSITION.exec(disposition);

        if (matches !== null && matches[1]) {
            return matches[1].replace(/['"]/g, '');
        }
    }

    return null;
};

export const downloadBlob = (data: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
};

export const downloadFileFromResponse = (response: AxiosResponse<Blob>) => {
    downloadBlob(
        response.data,
        getFileNameFromContentDisposition((response.headers as Record<string, string>)['content-disposition'] || '') || 'download',
    );
};