import { SystemActionsType, GET_COUNTRIES_SUCCESS } from './SystemActions';
import { SystemState } from './SystemState';

export const systemInitialState: SystemState = {
    countries: {},
};

export const systemReducer = (state = systemInitialState, action: SystemActionsType): SystemState => {
    switch (action.type) {
        case GET_COUNTRIES_SUCCESS:
            return {
                ...state,
                countries: {
                    ...state.countries,
                    ...action.payload.countries,
                },
            };
        default:
            return state;
    }
};
