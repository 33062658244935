import * as Yup from 'yup';

export const sequenceSchema = (list: Array<Yup.StringSchema>) => {
    return Yup.string().test(async (value, context) => {
        try {
            for (const schema of list) {
                await schema.validate(value);
            }
        } catch (error: unknown) {
            if (error instanceof Yup.ValidationError) {
                return context.createError({ message: error.message, type: error.type });
            }

            throw error;
        }

        return true;
    });
};
