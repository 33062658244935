import { Action, ActionCreatorsMapObject } from 'redux';

export interface AppAction<T extends string, P> extends Action<T> {
    payload: P;
}

export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P>(type: T, payload: P): AppAction<T, P>;
export function createAction<T extends string, P>(type: T, payload?: P) {
    return payload === undefined ? { type } : { type, payload };
}

export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<A[keyof A]>;

export const getBorrowerLoanKey = (loanId: number|null, loanApplicationId: number|null) => loanId || loanApplicationId && `la${loanApplicationId}` || '';
