import { useEffect } from "react";

interface ScriptConfig {
    id?: string;
    dataAttr?: Record<string, string>[];
}

const useScript = (url: string, config?: ScriptConfig) => {
    useEffect(() => {
        const src = document.querySelector(`script[src="${url}"]`);
        if (!src){
            const script = document.createElement('script');
            script.src = url;
            script.async = true;

            if (config?.id) {
                script.id = config.id;
            }

            if (config?.dataAttr) {
                config.dataAttr.forEach(elem => {
                    for (const [key, value] of Object.entries(elem)) {
                        script.dataset[key] = value;
                    }
                });
            }
            document.body.appendChild(script);
        }
    }, [url, config]);
};

export default useScript;
