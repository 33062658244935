import { Fragment } from "react";
import { RegisterContainer } from "../../containers/Borrower/Register/RegisterContainer";
import { Header } from "../../containers/Common/Header";

export const Register = () => {
    return <Fragment>
        <Header />
        <RegisterContainer />
    </Fragment>;
};
