import { Card, CardContent, CardMedia, Grid, makeStyles, Typography } from "@material-ui/core";
import { LocationCity } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { LoanType } from "../../../constants/LoanType";
import { BorrowerLoanModel } from "../../../models/loan/BorrowerLoanModel";
import { formatPounds } from "../../../utils/money";

const useStyles = makeStyles(theme => ({
    icon: {
        fontSize: '6rem',
    },
    photo: {
        marginTop: theme.spacing(2),
    },
    actions: {
        paddingTop: 0,
        paddingBottom: theme.spacing(2),
    },
    viewAction: {
        margin: 'auto',
    },
}));

interface PendingLoanWidgetProps {
    loan: BorrowerLoanModel;
}

export const PendingLoanWidget = (props: PendingLoanWidgetProps) => {
    const { loan } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    return <Card>
        <CardContent>
            <Typography variant="h5" align="center" gutterBottom>
                <b>{loan.loanType === LoanType.Development ? t('Development Loan') : t('Bridge Loan')}</b>
            </Typography>
            <Grid container>
                <Grid item xs justify="center" alignItems="center" container>
                    <CardMedia component={LocationCity} className={classes.icon} />
                </Grid>
                <Grid item xs>
                    <Typography>
                        {t('Loan Amount')}
                    </Typography>
                    <Typography gutterBottom>
                        <b>{formatPounds(loan.loanAmount)}</b>
                    </Typography>
                    <Typography>
                        {t('Loan Term')}
                    </Typography>
                    <Typography>
                        <b>{t('months', { count: loan.loanTerm })}</b>
                    </Typography>
                </Grid>
            </Grid>
        </CardContent>
    </Card>;
};
