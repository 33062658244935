import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Container,
    makeStyles,
    Theme,
    Typography,
    Link as MaterialLink, Grid, Card, CardContent, Divider,
} from '@material-ui/core';
import { Table } from '../../components/Table';
import { LoanActions } from '../../store/loan/LoanActions';
import {
    getDataRoomLoansLoading,
    getDataRoomLoansPaginated,
    getDataRoomLoansStatistics,
    getDataRoomLoansStatisticsLoading,
    getDataRoomLoansTotal,
} from '../../store/loan/LoanSelectors';
import { DataRoomLoanModel } from '../../models/loan/DataRoomLoanModel';
import { capitalizeFirstLetter } from '../../utils/string';
import { formatPounds, shortenFormatPounds } from '../../utils/money';
import useScript from '../../hooks/useScript';
import { LoanStatus } from '../../constants/LoanStatus';
import { ColumnDefinitionType } from '../../components/SortableHeader';
import { Skeleton } from '@material-ui/lab';
import { DataRoomLoansStatisticsModel } from '../../models/loan/DataRoomLoansStatisticsModel';
import * as React from 'react';
import { dropboxScriptConfig, dropboxSrc } from '../../constants/Dropbox';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    content: {
        marginTop: theme.spacing(4),
    },
    loansHeader:{
        marginTop:'30px',
    },
    cardContainer:{
        marginTop:'10px',
    },
    divider:{
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        maxWidth: '320px',
        margin: 'auto',
    },

}));

const columns: ColumnDefinitionType<keyof LoanDataTable>[] = [
    {
        key: 'displayName',
        header: 'NAME',
        sorting: true,
    },
    {
        key: 'loanType',
        header: 'LOAN TYPE',
        sorting: true,
    },
    {
        key: 'loanAmount',
        header: 'AMOUNT',
        sorting: true,
    },
    {
        key: 'loanTerm',
        header: 'LOAN TERM',
        sorting: true,
    },
    {
        key: 'state',
        header: 'STATUS',
        sorting: true,
    },
];

interface LoanDataTable {
    displayName: ReturnType<typeof MaterialLink>;
    loanType: string;
    loanAmount: string;
    loanTerm: string;
    state: string;
}

const mapStatusToString = (status: LoanStatus): string => {
    switch (status) {
        case LoanStatus.PIPELINE_NAI:
            return 'Pipeline';
        case LoanStatus.FUNDED_NAI:
            return 'Funded';
        case LoanStatus.ACTIVE_PARTIALLY_FUNDED_AI_PI:
            return 'Active - Partially Funded - Paying Interest';
        case LoanStatus.ACTIVE_FUNDED_AI_PI:
            return 'Active - Funded - Paying Interest';
        case LoanStatus.ACTIVE_FUNDED_IN_ARREARS_PI:
            return 'Active - Funded - In Arrears, Paying Interest';
        case LoanStatus.ACTIVE_FUNDED_AI_NPI:
            return 'Active - Funded - Accruing Interest';
        case LoanStatus.ACTIVE_FUNDED_NAI:
            return 'Active - Funded - Not Accruing Interest';
        case LoanStatus.DEFAULTED_NAI:
            return 'Defaulted';
        case LoanStatus.REPAID_NAI:
            return 'Repaid';
    }
};

const mapLoansToTableData = (loans: DataRoomLoanModel[]) => {
    return loans.map((loan: DataRoomLoanModel) => {
        return {
            'displayName':
                <MaterialLink
                    component={RouterLink}
                    to={`/dataroom/loans/${loan.loanId}`}
                >
                    {capitalizeFirstLetter(loan.displayName)}
                </MaterialLink>,
            'loanType': capitalizeFirstLetter(loan.loanType),
            'loanAmount': formatPounds(loan.loanAmount),
            'loanTerm': `${loan.loanTerm} months`,
            'state': mapStatusToString(loan.state),
        };
    });
};

const PAGE_SIZE = 50;

export const LoansContainer = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    useScript(dropboxSrc, dropboxScriptConfig);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);

    const dataRoomLoansLoading = useSelector(getDataRoomLoansLoading);
    const loans: DataRoomLoanModel[] = useSelector(getDataRoomLoansPaginated(page+1));
    const data = mapLoansToTableData(loans);
    const dataRoomLoansTotal = useSelector(getDataRoomLoansTotal);
    const statistics: DataRoomLoansStatisticsModel|null = useSelector(getDataRoomLoansStatistics);
    const statisticsLoaning = useSelector(getDataRoomLoansStatisticsLoading);

    useEffect(() => {
        dispatch(LoanActions.getDataRoomLoans(page+1, rowsPerPage));
    }, [dispatch, page, rowsPerPage]);

    useEffect(() => {
        if (!statistics){
            dispatch(LoanActions.getDataRoomLoansStatistics('active'));
        }
    }, [dispatch, statistics]);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number,
    ): void => {
        setPage(newPage);

    };

    const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
        const perPage = parseInt(event.target.value, 10);
        setRowsPerPage(perPage);
        setPage(0);
    };

    const amount = shortenFormatPounds(statistics?.amount || 0);

    return <Container maxWidth="lg" className={classes.container}>
        <Typography gutterBottom variant="h4" align="center">
            {t('Data Room')}
        </Typography>

        <Divider  className={classes.divider} orientation={'horizontal'} variant={'middle'} />
        <Grid item container justify="center" direction="row" spacing={6}
            className={classes.cardContainer}
        >
            <Grid item md={3} xs={7}>
                <Card>
                    <CardContent>
                        <Typography variant="h6" component="h2" align="center">
                            {t('Active Loans')}
                        </Typography>
                        <Typography variant="h3" align="center">
                            {statisticsLoaning ? <Skeleton /> : statistics?.count}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={3} xs={7}>
                <Card>
                    <CardContent>
                        <Typography variant="h6" component="h2" align="center">
                            {t('Loan Book Size')}
                        </Typography>
                        <Typography variant="h3" align="center">
                            {statisticsLoaning ? <Skeleton /> : amount}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>

        <Typography className={classes.loansHeader} variant="h6" gutterBottom>{t('Loans')}</Typography>
        <Table
            data={data}
            columns={columns}
            dataLoading={dataRoomLoansLoading}
            sortHandler={
                {
                    onChange: (
                        page: number, 
                        pageSize: number,
                        sortColumn: string | number | symbol,
                        order: string,
                    ) => dispatch(
                        LoanActions.getDataRoomLoans(
                            page, 
                            pageSize, 
                            sortColumn,
                            order,
                        ),
                    ),
                }
            }
            pagination={
                {
                    page: page,
                    rowsPerPage:rowsPerPage,
                    total: dataRoomLoansTotal,
                    handleChangePage:handleChangePage,
                    handleChangeRowsPerPage:handleChangeRowsPerPage,
                }
            }
        />
    </Container>;
};
