import { CREATE_LOAN_APPLICATION, CREATE_LOAN_APPLICATION_FAILED, CREATE_LOAN_APPLICATION_SUCCESS, GET_LOAN_APPLICATIONS, GET_LOAN_APPLICATIONS_FAILED, GET_LOAN_APPLICATIONS_SUCCESS, LoanApplicationActionsType, SUBMIT_LOAN_APPLICATION_SUCCESS } from './LoanApplicationActions';
import { LoanApplicationState } from './LoanApplicationState';

export const loanApplicationInitialState: LoanApplicationState = {
    loanApplicationSubmitted: false,
    loanApplications: {},
    createLoanApplicationInProcess: false,
    loanApplicationsPagination: {},
    loanApplicationsCount: 0,
    loanApplicationsLoading: false,
};

export const loanApplicationReducer = (state = loanApplicationInitialState, action: LoanApplicationActionsType): LoanApplicationState => {
    switch (action.type) {
        case CREATE_LOAN_APPLICATION:
            return {
                ...state,
                createLoanApplicationInProcess: true,
            };
        case CREATE_LOAN_APPLICATION_SUCCESS:
            return {
                ...state,
                createLoanApplicationInProcess: false,
            };
        case CREATE_LOAN_APPLICATION_FAILED:
            return {
                ...state,
                createLoanApplicationInProcess: false,
            };

        case SUBMIT_LOAN_APPLICATION_SUCCESS:
            return {
                ...state,
                loanApplicationSubmitted: true,
            };

        case GET_LOAN_APPLICATIONS:
            return {
                ...state,
                loanApplicationsLoading: true,
            };
        case GET_LOAN_APPLICATIONS_SUCCESS:
            return {
                ...state,
                loanApplications: {
                    ...state.loanApplications,
                    ...action.payload.loanApplications.reduce((acc, item) => ({ ...acc, [item.loanApplicationId]: item }), {}),
                },
                loanApplicationsPagination: {
                    ...state.loanApplicationsPagination,
                    [action.payload.page]: action.payload.loanApplications.map(item => item.loanApplicationId),
                },
                loanApplicationsLoading: false,
                loanApplicationsCount: action.payload.total,
            };
        case GET_LOAN_APPLICATIONS_FAILED:
            return {
                ...state,
                loanApplicationsLoading: false,
            };

        default:
            return state;
    }
};
