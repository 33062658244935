import { config } from "../config";

export const createUrl = (path: string, baseUrl: string, parameters?: Record<string, string>) => {
    const query = (new URLSearchParams(parameters)).toString();
    return (new URL(path + (query ? `?${query}` : ''), baseUrl)).toString();
};

export const appUrl = (path: string, parameters?: Record<string, string>) => createUrl(path, config.appUrl, parameters);

export const authUrl = (path: string, parameters?: Record<string, string>) => createUrl(path, config.authUrl, parameters);

export const apiUrl = (path: string, parameters?: Record<string, string>) => createUrl(path, config.apiUrl, parameters);

export const marketingUrl = (path: string, parameters?: Record<string, string>) => createUrl(path, config.marketingUrl, parameters);

export const addSearchParameters = (existingSearch: string, parameters: Record<string, string>): string => {
    const search = new URLSearchParams(existingSearch);

    Object.keys(parameters).forEach((key) => {
        search.append(key, parameters[key]);
    });

    return search.toString();
};
