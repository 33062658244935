import { all, fork } from 'redux-saga/effects';
import { authSaga } from './auth/AuthSaga';
import { systemSaga } from './system/SystemSaga';
import { loanSaga } from './loan/LoanSaga';
import { loanApplicationSaga } from './loanApplication/LoanApplicationSaga';

export default function* () {
    yield all([
        fork(authSaga),
        fork(systemSaga),
        fork(loanApplicationSaga),
        fork(loanSaga),
    ]);
}
