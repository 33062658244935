import { LoanState } from './LoanState';
import {
    GET_ACTIVE_BORROWER_LOANS,
    GET_ACTIVE_BORROWER_LOANS_FAILED,
    GET_ACTIVE_BORROWER_LOANS_SUCCESS,
    GET_BORROWER_LOAN,
    GET_BORROWER_LOAN_FAILED,
    GET_BORROWER_LOAN_SUCCESS,
    GET_COMPLETED_BORROWER_LOANS,
    GET_COMPLETED_BORROWER_LOANS_FAILED,
    GET_COMPLETED_BORROWER_LOANS_SUCCESS,
    GET_STATISTICS,
    GET_STATISTICS_FAILED,
    GET_STATISTICS_SUCCESS,
    GET_DATA_ROOM_LOAN,
    GET_DATA_ROOM_LOAN_FAILED,
    GET_DATA_ROOM_LOAN_SUCCESS,
    GET_DATA_ROOM_LOANS,
    GET_DATA_ROOM_LOANS_FAILED,
    GET_DATA_ROOM_LOANS_STATISTICS,
    GET_DATA_ROOM_LOANS_STATISTICS_FAILED,
    GET_DATA_ROOM_LOANS_STATISTICS_SUCCESS,
    GET_DATA_ROOM_LOANS_SUCCESS,
    GET_DROPBOX_LOAN_LINK,
    GET_DROPBOX_LOAN_LINK_FAILED,
    GET_DROPBOX_LOAN_LINK_SUCCESS,
    LoanActionsType,
    GET_PENDING_BORROWER_LOANS,
    GET_PENDING_BORROWER_LOANS_SUCCESS,
    GET_PENDING_BORROWER_LOANS_FAILED,
    DOWNLOAD_BORROWER_LOAN_STATEMENT,
    DOWNLOAD_BORROWER_LOAN_STATEMENT_SUCCESS,
    DOWNLOAD_BORROWER_LOAN_STATEMENT_FAILED,
    GET_DECLINED_BORROWER_LOANS,
    GET_DECLINED_BORROWER_LOANS_SUCCESS,
    GET_DECLINED_BORROWER_LOANS_FAILED,
} from './LoanActions';
import { getBorrowerLoanKey } from '../../utils/store';

export const loanInitialState: LoanState = {
    borrowerLoans: {},

    pendingBorrowerLoansPagination: {},
    pendingBorrowerLoansLoading: false,
    pendingBorrowerLoansCount: 0,
    declinedBorrowerLoansPagination: {},
    declinedBorrowerLoansLoading: false,
    declinedBorrowerLoansCount: 0,
    activeBorrowerLoansPagination: {},
    activeBorrowerLoansLoading: false,
    activeBorrowerLoansCount: 0,
    completedBorrowerLoansPagination: {},
    completedBorrowerLoansLoading: false,
    completedBorrowerLoansCount: 0,
    statistics: null,
    statisticsLoading: false,
    borrowerLoanLoading: false,

    dataRoomLoans: {},
    dataRoomLoansPagination: {},
    dataRoomLoansLoading: false,
    dataRoomLoansCount: 0,

    dataRoomLoansStatistics: null,
    dataRoomLoansStatisticsLoading: false,

    dataRoomLoanLoading: false,

    dropboxLoanLinks: {},
    dropboxLoanLinkLoading: false,

    borrowerLoanStatementDownloading: false,
};

export const loanReducer = (state = loanInitialState, action: LoanActionsType): LoanState => {
    switch (action.type) {
        case GET_PENDING_BORROWER_LOANS:
            return {
                ...state,
                pendingBorrowerLoansLoading: true,
            };
        case GET_PENDING_BORROWER_LOANS_SUCCESS:
            return {
                ...state,
                borrowerLoans: {
                    ...state.borrowerLoans,
                    ...action.payload.borrowerLoans.reduce((acc, item) => ({ ...acc, [getBorrowerLoanKey(item.loanId, item.loanApplicationId)]: item }), {}),
                },
                pendingBorrowerLoansPagination: {
                    ...state.pendingBorrowerLoansPagination,
                    [action.payload.page]: action.payload.borrowerLoans.map(item => getBorrowerLoanKey(item.loanId, item.loanApplicationId)),
                },
                pendingBorrowerLoansLoading: false,
                pendingBorrowerLoansCount: action.payload.total,
            };
        case GET_PENDING_BORROWER_LOANS_FAILED:
            return {
                ...state,
                pendingBorrowerLoansLoading: false,
            };

        case GET_DECLINED_BORROWER_LOANS:
            return {
                ...state,
                declinedBorrowerLoansLoading: true,
            };
        case GET_DECLINED_BORROWER_LOANS_SUCCESS:
            return {
                ...state,
                borrowerLoans: {
                    ...state.borrowerLoans,
                    ...action.payload.borrowerLoans.reduce((acc, item) => ({ ...acc, [getBorrowerLoanKey(item.loanId, item.loanApplicationId)]: item }), {}),
                },
                declinedBorrowerLoansPagination: {
                    ...state.declinedBorrowerLoansPagination,
                    [action.payload.page]: action.payload.borrowerLoans.map(item => getBorrowerLoanKey(item.loanId, item.loanApplicationId)),
                },
                declinedBorrowerLoansLoading: false,
                declinedBorrowerLoansCount: action.payload.total,
            };
        case GET_DECLINED_BORROWER_LOANS_FAILED:
            return {
                ...state,
                declinedBorrowerLoansLoading: false,
            };

        case GET_ACTIVE_BORROWER_LOANS:
            return {
                ...state,
                activeBorrowerLoansLoading: true,
            };
        case GET_ACTIVE_BORROWER_LOANS_SUCCESS:
            return {
                ...state,
                borrowerLoans: {
                    ...state.borrowerLoans,
                    ...action.payload.borrowerLoans.reduce((acc, item) => ({ ...acc, [getBorrowerLoanKey(item.loanId, item.loanApplicationId)]: item }), {}),
                },
                activeBorrowerLoansPagination: {
                    ...state.activeBorrowerLoansPagination,
                    [action.payload.page]: action.payload.borrowerLoans.map(item => getBorrowerLoanKey(item.loanId, item.loanApplicationId)),
                },
                activeBorrowerLoansLoading: false,
                activeBorrowerLoansCount: action.payload.total,
            };
        case GET_ACTIVE_BORROWER_LOANS_FAILED:
            return {
                ...state,
                activeBorrowerLoansLoading: false,
            };

        case GET_COMPLETED_BORROWER_LOANS:
            return {
                ...state,
                completedBorrowerLoansLoading: true,
            };
        case GET_COMPLETED_BORROWER_LOANS_SUCCESS:
            return {
                ...state,
                borrowerLoans: {
                    ...state.borrowerLoans,
                    ...action.payload.borrowerLoans.reduce((acc, item) => ({ ...acc, [getBorrowerLoanKey(item.loanId, item.loanApplicationId)]: item }), {}),
                },
                completedBorrowerLoansPagination: {
                    ...state.completedBorrowerLoansPagination,
                    [action.payload.page]: action.payload.borrowerLoans.map(item => getBorrowerLoanKey(item.loanId, item.loanApplicationId)),
                },
                completedBorrowerLoansLoading: false,
                completedBorrowerLoansCount: action.payload.total,
            };
        case GET_COMPLETED_BORROWER_LOANS_FAILED:
            return {
                ...state,
                completedBorrowerLoansLoading: false,
            };

        case GET_BORROWER_LOAN:
            return {
                ...state,
                borrowerLoanLoading: true,
            };
        case GET_BORROWER_LOAN_SUCCESS:
            return {
                ...state,
                borrowerLoans: {
                    ...state.borrowerLoans,
                    [getBorrowerLoanKey(action.payload.borrowerLoan.loanId, action.payload.borrowerLoan.loanApplicationId)]: action.payload.borrowerLoan,
                },
                borrowerLoanLoading: false,
            };
        case GET_BORROWER_LOAN_FAILED:
            return {
                ...state,
                borrowerLoanLoading: false,
            };

        case GET_STATISTICS:
            return {
                ...state,
                statisticsLoading: true,
            };
        case GET_STATISTICS_SUCCESS:
            return {
                ...state,
                statistics: action.payload.statistics,
                statisticsLoading: false,
            };
        case GET_STATISTICS_FAILED:
            return {
                ...state,
                statisticsLoading: false,
            };
        case GET_DATA_ROOM_LOANS:
            return {
                ...state,
                dataRoomLoansLoading: true,
            };
        case GET_DATA_ROOM_LOANS_SUCCESS:
            return {
                ...state,
                dataRoomLoans: {
                    ...state.dataRoomLoans,
                    ...action.payload.dataRoomLoans.reduce((acc, item) => ({ ...acc, [item.loanId]: item }), {}),
                },
                dataRoomLoansPagination: {
                    ...state.dataRoomLoansPagination,
                    [action.payload.page]: action.payload.dataRoomLoans.map(
                        item => item.loanId,
                    ),
                },
                dataRoomLoansCount: action.payload.dataRoomLoansCount,
                dataRoomLoansLoading: false,
            };
        case GET_DATA_ROOM_LOANS_FAILED:
            return {
                ...state,
                dataRoomLoansLoading: false,
            };
        case GET_DATA_ROOM_LOANS_STATISTICS:
            return {
                ...state,
                dataRoomLoansStatisticsLoading: true,
            };
        case GET_DATA_ROOM_LOANS_STATISTICS_SUCCESS:
            return {
                ...state,
                dataRoomLoansStatistics: action.payload.statistics,
                dataRoomLoansStatisticsLoading: false,
            };
        case GET_DATA_ROOM_LOANS_STATISTICS_FAILED:
            return {
                ...state,
                dataRoomLoansStatisticsLoading: false,
            };

        case GET_DATA_ROOM_LOAN:
            return {
                ...state,
                dataRoomLoanLoading: true,
            };
        case GET_DATA_ROOM_LOAN_SUCCESS:
            return {
                ...state,
                dataRoomLoans: {
                    ...state.dataRoomLoans,
                    [action.payload.dataRoomLoan.loanId]: action.payload.dataRoomLoan,
                },
                dataRoomLoanLoading: false,
            };
        case GET_DATA_ROOM_LOAN_FAILED:
            return {
                ...state,
                dataRoomLoanLoading: false,
            };

        case GET_DROPBOX_LOAN_LINK:
            return {
                ...state,
                dropboxLoanLinkLoading: true,
            };
        case GET_DROPBOX_LOAN_LINK_SUCCESS:
            return {
                ...state,
                dropboxLoanLinks: {
                    ...state.dropboxLoanLinks,
                    [action.payload.loanCode]: action.payload.link,
                },
                dropboxLoanLinkLoading: false,
            };
        case GET_DROPBOX_LOAN_LINK_FAILED:
            return {
                ...state,
                dropboxLoanLinkLoading: false,
            };
        case DOWNLOAD_BORROWER_LOAN_STATEMENT:
            return {
                ...state,
                borrowerLoanStatementDownloading: true,
            };
        case DOWNLOAD_BORROWER_LOAN_STATEMENT_SUCCESS:
            return {
                ...state,
                borrowerLoanStatementDownloading: false,
            };
        case DOWNLOAD_BORROWER_LOAN_STATEMENT_FAILED:
            return {
                ...state,
                borrowerLoanStatementDownloading: false,
            };
        default:
            return state;
    }
};
