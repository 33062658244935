class Storage {
    put = (key: string, value: string): void => {
        window.localStorage.setItem(key, value);
    };

    putObject = (key: string, data: Record<string, unknown>): void => {
        window.localStorage.setItem(key, JSON.stringify(data));
    };

    get = (key: string) => {
        return window.localStorage.getItem(key);
    };

    getObject = (key: string) => {
        const value = window.localStorage.getItem(key);

        if (value) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return JSON.parse(value);
        }

        return null;
    };

    delete = (key: string) => {
        window.localStorage.removeItem(key);
    };
}

export const storage = new Storage();
