import { BorrowerLoanModel } from "../../models/loan/BorrowerLoanModel";
import { StatisticsModel } from '../../models/loan/StatisticsModel';
import { ActionsUnion, createAction } from "../../utils/store";
import { DataRoomLoanModel } from '../../models/loan/DataRoomLoanModel';
import { DataRoomLoansStatisticsModel } from '../../models/loan/DataRoomLoansStatisticsModel';

export const GET_PENDING_BORROWER_LOANS = 'GET_PENDING_BORROWER_LOANS';
export const GET_PENDING_BORROWER_LOANS_SUCCESS = 'GET_PENDING_BORROWER_LOANS_SUCCESS';
export const GET_PENDING_BORROWER_LOANS_FAILED = 'GET_PENDING_BORROWER_LOANS_FAILED';

export const GET_DECLINED_BORROWER_LOANS = 'GET_DECLINED_BORROWER_LOANS';
export const GET_DECLINED_BORROWER_LOANS_SUCCESS = 'GET_DECLINED_BORROWER_LOANS_SUCCESS';
export const GET_DECLINED_BORROWER_LOANS_FAILED = 'GET_DECLINED_BORROWER_LOANS_FAILED';

export const GET_ACTIVE_BORROWER_LOANS = 'GET_ACTIVE_BORROWER_LOANS';
export const GET_ACTIVE_BORROWER_LOANS_SUCCESS = 'GET_ACTIVE_BORROWER_LOANS_SUCCESS';
export const GET_ACTIVE_BORROWER_LOANS_FAILED = 'GET_ACTIVE_BORROWER_LOANS_FAILED';

export const GET_COMPLETED_BORROWER_LOANS = 'GET_COMPLETED_BORROWER_LOANS';
export const GET_COMPLETED_BORROWER_LOANS_SUCCESS = 'GET_COMPLETED_BORROWER_LOANS_SUCCESS';
export const GET_COMPLETED_BORROWER_LOANS_FAILED = 'GET_COMPLETED_BORROWER_LOANS_FAILED';

export const GET_BORROWER_LOAN = 'GET_BORROWER_LOAN';
export const GET_BORROWER_LOAN_SUCCESS = 'GET_BORROWER_LOAN_SUCCESS';
export const GET_BORROWER_LOAN_FAILED = 'GET_BORROWER_LOAN_FAILED';

export const GET_STATISTICS = 'GET_STATISTICS';
export const GET_STATISTICS_SUCCESS = 'GET_STATISTICS_SUCCESS';
export const GET_STATISTICS_FAILED = 'GET_STATISTICS_FAILED';

export const GET_DATA_ROOM_LOANS = 'GET_DATA_ROOM_LOANS';
export const GET_DATA_ROOM_LOANS_SUCCESS = 'GET_DATA_ROOM_LOANS_SUCCESS';
export const GET_DATA_ROOM_LOANS_FAILED = 'GET_DATA_ROOM_LOANS_FAILED';

export const GET_DATA_ROOM_LOANS_STATISTICS = 'GET_DATA_ROOM_LOANS_STATISTICS';
export const GET_DATA_ROOM_LOANS_STATISTICS_SUCCESS = 'GET_DATA_ROOM_LOANS_STATISTICS_SUCCESS';
export const GET_DATA_ROOM_LOANS_STATISTICS_FAILED = 'GET_DATA_ROOM_LOANS_STATISTICS_FAILED';

export const GET_DATA_ROOM_LOAN = 'GET_DATA_ROOM_LOAN';
export const GET_DATA_ROOM_LOAN_SUCCESS = 'GET_DATA_ROOM_LOAN_SUCCESS';
export const GET_DATA_ROOM_LOAN_FAILED = 'GET_DATA_ROOM_LOAN_FAILED';

export const GET_DROPBOX_LOAN_LINK = 'GET_DROPBOX_LOAN_LINK';
export const GET_DROPBOX_LOAN_LINK_SUCCESS = 'GET_DROPBOX_LOAN_LINK_SUCCESS';
export const GET_DROPBOX_LOAN_LINK_FAILED = 'GET_DROPBOX_LOAN_LINK_FAILED';

export const DOWNLOAD_BORROWER_LOAN_STATEMENT = 'DOWNLOAD_BORROWER_LOAN_STATEMENT';
export const DOWNLOAD_BORROWER_LOAN_STATEMENT_SUCCESS = 'DOWNLOAD_BORROWER_LOAN_STATEMENT_SUCCESS';
export const DOWNLOAD_BORROWER_LOAN_STATEMENT_FAILED = 'DOWNLOAD_BORROWER_LOAN_STATEMENT_FAILED';

export const LoanActions = {
    getPendingBorrowerLoans: (page: number, pageSize: number) => createAction(GET_PENDING_BORROWER_LOANS, { page, pageSize }),
    getPendingBorrowerLoansSuccess: (borrowerLoans: BorrowerLoanModel[], total: number, page: number, pageSize: number) =>
        createAction(GET_PENDING_BORROWER_LOANS_SUCCESS, { borrowerLoans, total, page, pageSize }),
    getPendingBorrowerLoansFailed: () => createAction(GET_PENDING_BORROWER_LOANS_FAILED),

    getDeclinedBorrowerLoans: (page: number, pageSize: number) => createAction(GET_DECLINED_BORROWER_LOANS, { page, pageSize }),
    getDeclinedBorrowerLoansSuccess: (borrowerLoans: BorrowerLoanModel[], total: number, page: number, pageSize: number) =>
        createAction(GET_DECLINED_BORROWER_LOANS_SUCCESS, { borrowerLoans, total, page, pageSize }),
    getDeclinedBorrowerLoansFailed: () => createAction(GET_DECLINED_BORROWER_LOANS_FAILED),

    getActiveBorrowerLoans: (page: number, pageSize: number) => createAction(GET_ACTIVE_BORROWER_LOANS, { page, pageSize }),
    getActiveBorrowerLoansSuccess: (borrowerLoans: BorrowerLoanModel[], total: number, page: number, pageSize: number) =>
        createAction(GET_ACTIVE_BORROWER_LOANS_SUCCESS, { borrowerLoans, total, page, pageSize }),
    getActiveBorrowerLoansFailed: () => createAction(GET_ACTIVE_BORROWER_LOANS_FAILED),

    getCompletedBorrowerLoans: (page: number, pageSize: number) => createAction(GET_COMPLETED_BORROWER_LOANS, { page, pageSize }),
    getCompletedBorrowerLoansSuccess: (borrowerLoans: BorrowerLoanModel[], total: number, page: number, pageSize: number) =>
        createAction(GET_COMPLETED_BORROWER_LOANS_SUCCESS, { borrowerLoans, total, page, pageSize }),
    getCompletedBorrowerLoansFailed: () => createAction(GET_COMPLETED_BORROWER_LOANS_FAILED),

    getBorrowerLoan: (id: number) => createAction(GET_BORROWER_LOAN, { id }),
    getBorrowerLoanSuccess: (borrowerLoan: BorrowerLoanModel) => createAction(GET_BORROWER_LOAN_SUCCESS, { borrowerLoan }),
    getBorrowerLoanFailed: () => createAction(GET_BORROWER_LOAN_FAILED),

    getStatistics: () => createAction(GET_STATISTICS),
    getStatisticsSuccess: (statistics: StatisticsModel) => createAction(GET_STATISTICS_SUCCESS, { statistics }),
    getStatisticsFailed: () => createAction(GET_STATISTICS_FAILED),

    getDataRoomLoans: (
        page: number,
        pageSize: number,
        column?: string|number|symbol,
        order?: string,
    ) => createAction(GET_DATA_ROOM_LOANS, { page, pageSize, column, order }),
    getDataRoomLoansSuccess: (
        dataRoomLoans: DataRoomLoanModel[],
        dataRoomLoansCount: number,
        page: number,
    ) => createAction(GET_DATA_ROOM_LOANS_SUCCESS, { dataRoomLoans, dataRoomLoansCount, page }),
    getDataRoomLoansFailed: () => createAction(GET_DATA_ROOM_LOANS_FAILED),

    getDataRoomLoansStatistics: (status?: 'active' | 'pending' | 'completed') => createAction(GET_DATA_ROOM_LOANS_STATISTICS, { status }),
    getDataRoomLoansStatisticsSuccess: (statistics: DataRoomLoansStatisticsModel) => createAction(GET_DATA_ROOM_LOANS_STATISTICS_SUCCESS, { statistics }),
    getDataRoomLoansStatisticsFailed: () => createAction(
        GET_DATA_ROOM_LOANS_STATISTICS_FAILED,
    ),

    getDataRoomLoan: (id: number) => createAction(GET_DATA_ROOM_LOAN, { id }),
    getDataRoomLoanSuccess: (dataRoomLoan: DataRoomLoanModel) => createAction(GET_DATA_ROOM_LOAN_SUCCESS, { dataRoomLoan }),
    getDataRoomLoanFailed: () => createAction(GET_DATA_ROOM_LOAN_FAILED),

    getDropboxLoanLink: (loanCode: string) => createAction(GET_DROPBOX_LOAN_LINK, { loanCode }),
    getDropboxLoanLinkSuccess: (loanCode: string, link: string | null) => createAction(GET_DROPBOX_LOAN_LINK_SUCCESS, { loanCode, link }),
    getDropboxLoanLinkFailed: () => createAction(
        GET_DROPBOX_LOAN_LINK_FAILED,
    ),

    downloadBorrowerLoanStatement: (loanId: number) => createAction(DOWNLOAD_BORROWER_LOAN_STATEMENT, { loanId }),
    downloadBorrowerLoanStatementSuccess: () => createAction(DOWNLOAD_BORROWER_LOAN_STATEMENT_SUCCESS),
    downloadBorrowerLoanStatementFailed: () => createAction(DOWNLOAD_BORROWER_LOAN_STATEMENT_FAILED),
};

export type LoanActionsType = ActionsUnion<typeof LoanActions>;
