import { Box, IconButton, InputAdornment, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import ReCaptcha from "react-google-recaptcha";
import { MouseEvent, useState } from "react";
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from "@material-ui/icons";
import { config } from "../../../../config";
import { TFunction } from "i18next";
import * as Yup from 'yup';
import { CONTACT_NUMBER, PASSWORD_LETTERS, PASSWORD_NUMBERS, PASSWORD_SPECIAL_CHARACTERS } from "../../../../constants/RegExp";
import { authService } from "../../../../services/authService";

const useStyles = makeStyles((theme: Theme) => ({
    section: {
        maxWidth: theme.spacing(68),
        '&:not(:first-child)': {
            marginTop: theme.spacing(4),
        },
    },
}));

interface Values {
    email: string;
    contactNumber: string;
    password: string;
    captcha: string;
}

let lastEmailValidationResult = false;
let lastEmailValidationValue = '';

export const submitValidationSchema = (t: TFunction) => Yup.object().shape({
    email: Yup.string().sequence([
        Yup.string().email(t('Please enter a valid e-mail address')),
        Yup.string().required(t('Please enter your e-mail address')),
        Yup.string().test('checkEmailUnique', t('This email is already registered'), async (value) => {
            if (!value) {
                return false;
            }

            // Make sure we call the endpoint only when the value changes
            if (value !== lastEmailValidationValue) {
                lastEmailValidationResult = await authService.checkBorrowerEmailAvailable(value);
                lastEmailValidationValue = value;
            }

            return lastEmailValidationResult;
        }),
    ]),
    contactNumber: Yup.string()
        .matches(CONTACT_NUMBER, 'Please enter a correct contact number')
        .required(t('Please enter your countact number')),
    password: Yup.string()
        .min(8, (options) => t('Password length can not be less than {{min}} symbols', options))
        .matches(PASSWORD_LETTERS, t('Password must contain at least 1 letter'))
        .matches(PASSWORD_NUMBERS, t('Password must contain at least 1 number'))
        .matches(PASSWORD_SPECIAL_CHARACTERS, t('Password must contain at least one special character'))
        .required(t('Please enter a password')),
    captcha: Yup.string().required(t('Please resolve captcha')),
});

export const submitInitialValues = {
    email: '',
    contactNumber: '',
    password: '',
    captcha: '',
};

export const SubmitStep = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const formik = useFormikContext<Values>();

    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordMouseDown = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
    };

    return <Box>
        <Typography gutterBottom variant="h5">
            {t('Submit your application')}
        </Typography>

        <Box className={classes.section}>
            <Typography gutterBottom variant="h5">
                <b>{t('Contact Details')}</b>
            </Typography>
            <TextField
                fullWidth
                name="email"
                label={t('E-mail')}
                placeholder={t('Please enter your e-mail')}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="dense"
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
                fullWidth
                name="contactNumber"
                type="tel"
                label={t('Contact Number')}
                placeholder={t('Please enter your contact number')}
                value={formik.values.contactNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="dense"
                error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
                helperText={formik.touched.contactNumber && formik.errors.contactNumber}
            />
        </Box>

        <Box className={classes.section}>
            <Typography gutterBottom variant="h5">
                <b>{t('Account Details')}</b>
            </Typography>
            <TextField
                fullWidth
                type={showPassword ? 'text' : 'password' }
                name="password"
                label={t('Password')}
                placeholder={t('Please enter password')}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="dense"
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={handlePasswordMouseDown}
                        >
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                    </InputAdornment>,
                }}
            />
        </Box>

        <Box className={classes.section}>
            <ReCaptcha
                sitekey={config.reCaptchaSiteKey}
                onChange={(value) => formik.setFieldValue('captcha', value)}
            />
        </Box>
    </Box>;
};
